import { useCallback, useEffect, useRef, useState } from "react";
import { useUploadThing } from "../uploadthing";
import { useDropzone } from "react-dropzone";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UserImage from "../UserImage/UserImage";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../redux/authSlice";
import Cookies from "universal-cookie";
import { fileUploadApi } from "../../data/api/fileUpload";
import axiosConfig from "../../data/utils/axiosConfigInstance";

const styles = {
  progress: {
    "& .MuiTypography-root": {
      fontFamily: "Arial",
      color: "white",
      fontWeight: 700,
    },
  },
};

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={[
          {
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          styles.progress,
        ]}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
function UploadProfileImage({
  user,
  // imageRequiresValidation,
  // setImageRequiresValidation, 
}) {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [imageURL, setImageURL] = useState(null);

  const getProfileImage = () => {
    axiosConfig
      .get(`${process.env.REACT_APP_API_URL}/uploadthing`, {
        params: {
          userId: user?.userId,
        },
      })
      .then((res) => {
        setImageURL(res.data?.url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProfileImage();
  }, []);

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];

      setFile(file);
      setUploadProgress(0);

      setIsUploading(true);
      let response = await fileUploadApi(file);

      try {
        // Directly upload the image and update user profile without validation
        await axiosConfig.post(`${process.env.REACT_APP_API_URL}/uploadthing`, {
          userId: user.userId,
          imageURL: response.data.cdnLink,
        });

        // Update profile image URL
        getProfileImage();
        setIsUploading(false);
        console.log(response);
        toast.success("Image replaced successfully!");
        // setImageRequiresValidation(true);
        // -------------------------------New Addition----------------------------------------
        // Update user info in cookies and Redux store
        const userUpdated = {
          ...user,
          image: response.data.cdnLink,
        };

        cookies.set("user", userUpdated);
        localStorage.setItem("userData", JSON.stringify(userUpdated));
        dispatch(setUserInfo(userUpdated));

        setFile(null);
      } catch (error) {
        console.log(error);
        toast.error("Failed to upload image. Please try again later.");
        setIsUploading(false);
      }
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      onDrop(acceptedFiles);
    },
    accept: {
      "image/gif": [".gif"],
      "image/jpeg": [".jpe", ".jpg", ".jpeg"],
      "image/svg+xml": [".svg"],
      "image/png": [".png"],
    },
  });

  return (
    <div class="c-myaccount_main_box c-myaccount_main_box2 c-myaccount-notifications">
      <h4>PROFILE IMAGE</h4>

      <div
        className="c-my-acc_form_box c-my-acc_form_box-2"
        style={{ position: "relative" }}
      >
        {isUploading && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              right: 32,
              transform: "translateY(-50%)",
            }}
          >
            <CircularProgressWithLabel value={uploadProgress} />
          </div>
        )}
        {/* {imageRequiresValidation && (
          <div
            className={"c-myaccount-notifications-label"}
            style={{
              position: "absolute",
              top: "-46px",
            }}
          >
            Pending verification
          </div>
        )} */}

        <div
          className={"c-myaccount-profile-image-wrapper"}
          {...getRootProps()}
        >
          <div className={"c-myaccount-profile-image-display"}>
            <input
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                visibility: "hidden",
              }}
              {...getInputProps()}
            />
            <UserImage
              height={80}
              width={80}
              imageURL={imageURL}
            />
          </div>
        </div>
      </div>

      <div classname={"c-myaccount-profile-select"}>
        <button
          className={"c-form_my-btn"}
          onClick={() => {
            if (fileInputRef.current) fileInputRef.current.click();
          }}
        >
          Select...
        </button>
        <input
          ref={fileInputRef}
          id={"image_upload_input"}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            visibility: "hidden",
          }}
          type={"file"}
          accept={".gif,.jpe,.jpg,.jpeg,.svg,.png"}
          onChange={(e) => {
            onDrop(e.target.files);
          }}
        />
      </div>
    </div>
  );
}

export default UploadProfileImage;



