import React, { useEffect, useRef } from "react";
import { useContext, useState } from "react";
import "../Asset/NewSignUp.css";
import Logo from "../Asset/images/logo-blaxk.webp";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoaderComponet from "./LoderComponent";
import { setUserInfo } from "../redux/authSlice";
import { useDispatch } from "react-redux";
import { UserContext } from "./LoginContext/UserContext";
import Cookies from "universal-cookie";
import XButton from "./XButton/XButton";
import { SIGNUP_PLAN_WAIT_TIME } from "../data/utils/globals";
import ChargbeeButton from "./PlanPage/ChargbeeButton";
import { LineBreaker } from "../atomicComponent/AtomicComponents";
import { useSetAtom } from "jotai";
import { StudioSignupApi, deleteUserChangeApi } from "../data/api/auth";
import { userInfoAtom } from "../data/atoms/utilsAtoms";
import { StudioPlan } from "../data/utils/plansData";
import ShowIcon from "../Asset/images/show-regular-24.png";
import HideIcon from "../Asset/images/hide-regular-24.png";
import Facebook from "./Facebook";
import ChangeUserModal from "./ChangeUserModal";

export default function StudioSignUp() {
  const setUserData = useSetAtom(userInfoAtom);
  axios.defaults.withCredentials = true;
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [reEnteredPassword, setReEnteredPassword] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [errMess, setErrMess] = useState("");
  const { setUser } = useContext(UserContext);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [reEnteredPasswordError, setReEnteredPasswordError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [successResponse, setSuccessResponse] = useState("");
  const [homeNavigateButtonHidden, setHomeNavigateButtonHidden] =
    useState(true);

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [prevUserType, setPrevUserType] = useState(null);
  const [existingUser, setExistinUser] = useState();

  const handleLogoClick = () => {
    navigate("/");
  };

  const urlParams = new URLSearchParams(window.location.search);
  const planParam = urlParams.get("plan");

  const handleSubmit = async (e) => {
    setLoading(true);
    if(e) {
      
      
      e.preventDefault()
      
    }
   
    setErrorMessage("");
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setPasswordError("");
    setReEnteredPasswordError("");
    if (!firstName || !lastName || !email || !password || !reEnteredPassword) {
      setFirstNameError(firstName ? "" : "Please fill in your first name");
      setLastNameError(lastName ? "" : "Please fill in your last name");
      setEmailError(email ? "" : "Please fill in your email");
      setPasswordError(password ? "" : "Please Enter your password");
      setReEnteredPasswordError(
        reEnteredPassword ? "" : "Please re-enter your password"
      );
      setLoading(false);
      return;
    }

    const formData = {
      firstname: firstName,
      lastname: lastName,
      email: email,
      password: password,
      reEnteredPassword: reEnteredPassword,
      userType: "studio-owner",
    };

    if (password !== reEnteredPassword) {
      setReEnteredPasswordError("Passwords do not match");
      setLoading(false);
      return; // Do not proceed further if passwords don't match
    }

    if (!isCheckboxChecked) {
      setErrorMessage("Please accept the Terms & Conditions");
      setLoading(false);
      return;
    }

    try {
      window.fbq("trackCustom", "SignUp-AccountCreated", {
        CustomEvent: "SignUp-AccountCreated",
      });
      const response = await StudioSignupApi(formData);
      setLoading(false);
      if (response.status === 200) {
        console.log("from studio signup", response.data);
        // Successful signup
        const { firstname, lastname } = response.data;
        setUserInfo({ firstname, lastname });
        setUser({ firstname, lastname, email });
        localStorage.setItem("userData", JSON.stringify(response.data));
        localStorage.setItem("token", JSON.stringify(response.data?.token));
        localStorage.setItem("role", JSON.stringify(response.data?.role));
        cookies.set("user", response.data);
        cookies.set("token", response.data.token);
        dispatch(setUserInfo(response.data));
        // Dispatch to atom
        setUserData({
          token: response.data?.token,
          user: response.data,
          role: response.data?.role,
        });
        setErrMess("");
        handleSuccessResponse("Account created successfully!");
      }
      window?.fbq("trackCustom", "SignUpFormComplete", {
        CustomEvent: "SignUpFormComplete",
      });
    } catch (error) {
      setLoading(false);
      if (error.response) {
        setErrMess(error.response.data.message);
        if (!error.response.data?.user?.userType) {
          setShowModal(true); 
          setExistinUser(error.response.data?.user);
          setPrevUserType("individual-user"); 
        }
      }
      console.error("Error:catch ", error);
    }
  };

  const pageRef = useRef(null);

  const login = () => {
    navigate("/login");
  };

  useEffect(() => {
    if (successResponse) {
      setTimeout(() => {
        const targetId = "pick-plan";
        const targetId2 = "plan-section";
        let targetElement = document.getElementById(targetId);
        let targetElement2 = document.getElementById(targetId2);

        if (window.screen.width > 990 && targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        } else if (window.screen.width <= 990 && targetElement2) {
          targetElement2.scrollIntoView({ behavior: "smooth" });
        }
      }, 10);
    }
  }, [successResponse]);

  const handleConfirmUserChange = async() => {
    const deleteUserId = existingUser?._id;
    try {
      const response = await deleteUserChangeApi(deleteUserId);
      if(response.status === 200) {
        console.log("user deleted successfully")
        // setTimeOut(() => handleSubmit(),1000);
        handleSubmit()
      }
    }catch(err) {
      console.log(err)
    }finally {
      setShowModal(false);
    }
    // if (deleteUserId) {
    //   deleteStudioUser(deleteUserId);
    // }
  
  };

  const handleSuccessResponse = (message) => {
    setSuccessResponse(message);
    setTimeout(() => {
      setHomeNavigateButtonHidden(false);
    }, SIGNUP_PLAN_WAIT_TIME);
  };

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isRePasswordVisible, setIsRePasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const toggleRePasswordVisibility = () => {
    setIsRePasswordVisible(!isRePasswordVisible);
  };

  const [startTriggerred, setStartTriggerred] = useState(false);
  const handleFormStartTrigger = () => {
    if (!startTriggerred) {
      window?.fbq("trackCustom", "SignUpFormStart", {
        CustomEvent: "SignUpFormStart",
      });
      setStartTriggerred(true);
    }
  };
 
  return (
    <>
      {loading ? <LoaderComponet loading /> : " "}
      <Facebook />
      {showModal && <ChangeUserModal open = {showModal} userType={prevUserType} onClose={() => setShowModal(false)} handleCancelClick={login} onSave={handleConfirmUserChange} />}
      <header className="c-sing-header">
        <div className="row m-0">
          <div className="col-md-12">
            <div className="c-sing_logo--container">
              <div className="c-sing_logo" onClick={handleLogoClick}>
                <img src={Logo} alt="" srcSet="" />
              </div>
            </div>
          </div>
        </div>
      </header>
      <section
        className={`c-sing_form ${
          successResponse.length > 0 ? "c-sing_form--success" : ""
        }`}
        ref={pageRef}
      >
        <div className="container">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div class="c-sing_heading">
              <h2>Sign-Up </h2>
            </div>
            <div className="c-sing_form-box">
              <div>
                <form className="inner-container" onSubmit={handleSubmit}>
                  <XButton
                    onClick={() => {
                      navigate("/");
                    }}
                  />
                  <div style={{ height: "40px" }} />
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First name"
                        value={firstName}
                        label="First Name"
                        name="firstname"
                        onChange={(e) => {
                          setFirstName(e.target.value);
                          handleFormStartTrigger();
                        }}
                        disabled={successResponse !== ""}
                      />
                      {firstNameError && (
                        <p style={{ color: "red" }}>{firstNameError}</p>
                      )}
                    </div>
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last name"
                        value={lastName}
                        label="Last Name"
                        name="lastname"
                        onChange={(e) => {
                          setLastName(e.target.value);
                          handleFormStartTrigger();
                        }}
                        disabled={successResponse !== ""}
                      />
                      {lastNameError && (
                        <p style={{ color: "red" }}>{lastNameError}</p>
                      )}
                    </div>
                    <div className="col-md-12 form-group">
                      <input
                        type="email"
                        className="form-control"
                        id="inputEmail4"
                        placeholder="Email"
                        value={email}
                        label="Email"
                        name="email"
                        onChange={(e) => {
                          setEmail(e.target.value);
                          handleFormStartTrigger();
                        }}
                        disabled={successResponse !== ""}
                      />
                      {emailError && (
                        <p style={{ color: "red" }}>{emailError}</p>
                      )}
                    </div>
                    <div
                      className="col-md-12 form-group"
                      style={{ position: "relative" }}
                    >
                      <input
                        type={isPasswordVisible ? "text" : "password"}
                        className="form-control"
                        placeholder="Password"
                        value={password}
                        label="Password"
                        name="password"
                        onChange={(e) => {
                          setPassword(e.target.value);
                          handleFormStartTrigger();
                        }}
                        disabled={successResponse !== ""}
                      />
                      <span
                        className="c-password_visibility--icon"
                        onClick={togglePasswordVisibility}
                      >
                        <img src={isPasswordVisible ? ShowIcon : HideIcon} />
                      </span>
                      {passwordError && (
                        <p style={{ color: "red" }}>{passwordError}</p>
                      )}
                    </div>
                    <div
                      className="col-md-12 form-group"
                      style={{ position: "relative" }}
                    >
                      <input
                        type={isRePasswordVisible ? "text" : "password"}
                        className="form-control"
                        placeholder="Re-Enter Password"
                        value={reEnteredPassword}
                        label="Re-Enter Password"
                        name="repassword"
                        onChange={(e) => {
                          setReEnteredPassword(e.target.value);
                          handleFormStartTrigger();
                        }}
                        disabled={successResponse !== ""}
                      />
                      <span
                        className="c-password_visibility--icon"
                        onClick={toggleRePasswordVisibility}
                      >
                        <img src={isRePasswordVisible ? ShowIcon : HideIcon} />
                      </span>
                      {setReEnteredPasswordError && (
                        <p style={{ color: "red" }}>
                          {setReEnteredPasswordError}
                        </p>
                      )}
                      {reEnteredPasswordError && (
                        <p style={{ color: "red" }}>{reEnteredPasswordError}</p>
                      )}
                    </div>

                    <div className="col-md-12 form-group">
                      <div
                        className="form-check d-flex p-0"
                        onClick={() => {
                          setIsCheckboxChecked((prev) => !prev);
                          handleFormStartTrigger();
                        }}
                      >
                        <input
                          // className="form-check-input"
                          style={{ width: "20px", height: "20px" }}
                          checked={isCheckboxChecked}
                          type="checkbox"
                          id="gridCheck"
                          disabled={successResponse !== ""}
                        />
                        <label
                          className="form-check-label"
                          style={{ fontSize: "15px" }}
                        >
                          I agree with the{" "}
                          <a
                            href="https://thegodfreymethod.com/pages/terms-conditions"
                            target="_BLANK"
                            rel="noreferrer"
                          >
                            Terms &amp; Conditions
                          </a>{" "}
                          of The Godfrey Method
                        </label>
                      </div>
                      {errorMessage && (
                        <p style={{ color: "red" }}>{errorMessage}</p>
                      )}
                      {successResponse && (
                        <p style={{ color: "green" }}>{successResponse}</p>
                      )}
                    </div>
                    <div className="col-md-12 form-group">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={successResponse}
                      >
                        SIGN UP FOR STUDIO ACCOUNT
                      </button>
                      {errMess && <p style={{ color: "red" }}>{errMess}</p>}
                    </div>
                    <div className="col-md-12 form-group">
                      <p>
                        Already have an account?{" "}
                        <a onClick={login} style={{ cursor: "pointer" }}>
                          Login
                        </a>{" "}
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {successResponse && (
            <>
              <LineBreaker height={250} />
              <div className="c-signup_plan-container">
                {planParam ? (
                  <>
                    <YearlyPlan />
                  </>
                ) : (
                  <>
                    <div
                      id="pick-plan"
                      className="c-tab_cata-text c-tab_cata-text-signup--success c-plane_page-m"
                    >
                      <h4>Pick your plan</h4>
                    </div>
                    <div
                      id="plan-section"
                      className="c-plan_section-signup--success"
                    >
                      <div className="c-plan_section-sub-signup--success">
                        <div className="c-video_textcol">
                          <h3 className="c-plane-mobile_h3">Pick your Plan</h3>
                        </div>
                        <div className="c-plan_list-signup--success">
                          <YearlyPlan />
                        </div>
                      </div>
                      {!homeNavigateButtonHidden && (
                        <div className="c-signup_plan-button-container">
                          <button
                            className="c-signup_plan-button"
                            onClick={() => navigate("/home")}
                          >
                            Continue to Dashboard
                          </button>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
}

const YearlyPlan = () => {
  return (
    <div className="c-signup_plan-studio" style={{ width: "100%" }}>
      {StudioPlan.map((data, index) => (
        <div className="c-plan_new-two" key={index}>
          <div>
            <div>
              <h4>{data.name}</h4>
              <h2>
                {data.price}
                <span>/Yearly</span>
              </h2>
              <ul>
                {data.feature.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
              <ChargbeeButton
                planType="yearly"
                seats={data.seats}
                newlySignedUp={true}
                isStudio={true}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
