import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Switch from "@mui/material/Switch";
import api from "../data/api";
import SubUserAdd from "./SubUserAdd";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../redux/authSlice";
import Cookies from "universal-cookie";
import UploadProfileImage from "./UploadProfileImage/UploadProfileImage";
import { useAtom } from "jotai";
import { userInfoAtom } from "../data/atoms/utilsAtoms";
import Facebook from "./Facebook";

import ShowIcon from "../Asset/images/show-regular-24.png";
import HideIcon from "../Asset/images/hide-regular-24.png";

function MyAccount() {
  //if parameter has ?success=true, show success message
  const searchParams = new URLSearchParams(window.location.search);
  const success = searchParams.get("success");

  if (success === "true") {
    window?.fbq("trackCustom", "WebApp_SignUp_ChargebeeCompleted");
    window?.fbq("track", "PageView");
  }

  const [userData, setUserData] = useAtom(userInfoAtom);

  // const userData= useAtomValue(userInfoAtom)
  // console.log(userData.user.userType)
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newFirstName, setNewFirstName] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [buttonText, setButtonText] = useState("UPDATE");
  const [emailButtonText, setEmailButtonText] = useState("UPDATE");
  const [showSubscription, setShowSubscription] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState({});
  const [showPersonal, setShowPersonal] = useState(true);
  const [activeTab, setActiveTab] = useState("personal");
  const [staus, setStaus] = useState("");
  const [plan, setPlan] = useState("");
  const [nextBilling, setNextBilling] = useState("");
  const [method, setMethod] = useState("");
  const [card, setCard] = useState("");
  const [seatsData, setSeatsData] = useState();
  const userInfo = useSelector((state) => state.authState.userInfo);
  const [imageRequiresValidation, setImageRequiresValidation] = useState(
    userInfo?.imageRequiresValidation
  );
  const redirect = useNavigate();
  const handleShowPersonal = () => {
    setShowPersonal(true);
    setShowSubscription(false);
    setActiveTab("personal");
  };
  const handleShowSubscription = () => {
    setShowPersonal(false);
    setShowSubscription(true);
    setActiveTab("subscription");
  };

  // Notifications.
  const dispatch = useDispatch();
  const [commentNotifications, setCommentNotifications] = useState(
    userInfo?.commentNotifications ?? false
  );
  const cookies = new Cookies();
  const updateNotifications = useCallback(async () => {
    try {
      const resp = await api.updateNotifications({ commentNotifications });

      const user = { ...userInfo, commentNotifications };
      cookies.set("user", user);
      localStorage.setItem("userData", JSON.stringify(user));
      dispatch(setUserInfo(user));
      setUserData(user);

      setNotificationButtonStyle({
        background: "green",
        color: "black",
      });
      setNotificationButtonText(
        `NOTIFICATIONS ${commentNotifications ? "ON" : "OFF"} `
      );
      toast.success(resp.data.message, {
        position: "top-center",
        duration: 3000,
      });
    } catch (err) {
      toast.error(err.response.data.message, {
        position: "top-center",
        duration: 3000,
      });
    }
  }, [commentNotifications, userInfo]);

  const [nameButtonText, setNameButtonText] = useState("UPDATE");

  // Add a state for name button style
  const [nameButtonStyle, setNameButtonStyle] = useState({
    background: "",
    color: "",
  });

  // Add a state for name input styles
  const [nameInputStyles, setNameInputStyles] = useState({
    firstName: {
      borderRadius: "",
      backgroundColor: "",
      color: "",
    },
    lastName: {
      borderRadius: "",
      backgroundColor: "",
      color: "",
    },
  });

  useEffect(() => {
    const userId = userData.user?.userId;
    console.log("user id", userId);
    const findData = async () => {
      try {
        const response = await api.getSubscriptioDetails(userId);
        console.log("response data subscription", response);
        if (response.status === 200) {
          setSubscriptionData(response?.data);
          setStaus(response?.data?.subscriptionStatus);
          setPlan(response?.data?.billing_period_unit);
          const date = new Date(response?.data?.next_billing_at * 1000);
          setNextBilling(date.toLocaleDateString());
          setMethod(response?.data?.card_type);
          setCard(
            response?.data?.masked_number
              ? (response?.data?.masked_number).replace(/\*/g, "")
              : ""
          );
        } else if (response.status === 201) {
          toast.error('"Please Login Again', {
            position: "top-center",
            duration: 3000,
          });
          redirect("/login");
        }
      } catch (error) {
        if (error.response.status === 401) {
          toast.error(error.response.data.message, {
            position: "top-center",
            duration: 3000,
          });
        }
        setStaus("No data Found");
        setPlan("No data Found");
        setNextBilling("No data Found");
        setCard("No data Found");
        console.log(error);
      }
    };

    if (activeTab === "subscription" && userId) {
      findData();
    }
  }, [activeTab]);

  useEffect(() => {
    // const userId = userInfo && userInfo?.userId ? userInfo?.userId : "";
    const userId = userData.user?.userId;
    const findData = async () => {
      try {
        const response = await api.getPersonalInformation(userId);
        if (response.status === 200) {
          setFirstname(response?.data?.firstname);
          setLastname(response?.data?.lastname);
          setEmail(response?.data?.email);
          setImageRequiresValidation(response?.data?.imageRequiresValidation);
        } else if (response.status === 201) {
          toast.error('"Please Login Again', {
            position: "top-center",
            duration: 3000,
          });
          redirect("/login");
        }
      } catch (error) {
        if (error.response.status === 401) {
          toast.error(error?.response?.data?.message, {
            position: "top-center",
            duration: 3000,
          });
        }
        console.log(error);
      }
    };

    if (activeTab === "personal" && userId) {
      findData();
    }
  }, [activeTab]);

  //===============||Update Email|===========

  // Add a state for email input style
  const [emailInputStyle, setEmailInputStyle] = useState({
    backgroundColor: "",
    color: "",
  });
  const [emailInputStyles, setEmailInputStyles] = useState({
    email: {
      backgroundColor: "",
      color: "",
      border: "",
    },
    newEmail: {
      backgroundColor: "",
      color: "",
      border: "",
    },
    confirmEmail: {
      backgroundColor: "",
      color: "",
      border: "",
    },
  });

  const displayFrequency = (freq) => {
    switch (freq) {
      case "month":
        return "Monthly";
      case "annual":
        return "Annual";
      default:
        return freq;
    }
  };

  const displayStatus = (status) => {
    switch (status) {
      case "active":
        return "Active";
      case "future":
        return "Future";
      case "in_trial":
        return "Trial";
      case "non_renewing":
        return "Non Renewing";
      case "paused":
        return "Paused";
      case "cancelled":
        return "Cancelled";
      default:
        return status;
    }
  };

  const handleUpdateEmail = async () => {
    const userId = userInfo && userInfo?.userId ? userInfo?.userId : "";
    if (userData.user.userType === "subuser") {
      toast.error("Please contact you studio owner to update the email", {
        position: "top-center",
        duration: 3000,
      });
      return;
    }
    // Check if newEmail and confirmEmail have valid email format
    const emailFormatRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (
      !emailFormatRegex.test(newEmail) ||
      !emailFormatRegex.test(confirmEmail) ||
      newEmail !== confirmEmail
    ) {
      // Update email input styles for invalid email format or mismatch
      setEmailInputStyles({
        email: {
          backgroundColor: "red",
          color: "white",
          border: "",
        },
        newEmail: {
          backgroundColor: newEmail !== confirmEmail ? "red" : "",
          color: "white",
          border: "",
        },
        confirmEmail: {
          backgroundColor: newEmail !== confirmEmail ? "red" : "",
          color: "white",
          border: "",
        },
      });

      toast.error("Invalid email format", {
        position: "top-center",
        duration: 3000,
      });
      return;
    }
    try {
      const response = await api.UpdatePersonalInformationEmail(
        confirmEmail,
        userId
      );

      if (response.status === 200) {
        setEmail(" ");
        setEmail(confirmEmail);

        console.log(response.data.message);
        toast.success(response.data.message, {
          position: "top-center",
          duration: 3000,
        });

        // Update email input styles
        setEmailInputStyles({
          email: {
            // background: "green",
            color: "white",
            border: "1px solid green",
          },
          newEmail: {
            // backgroundColor: "green",
            color: "white",
            border: "1px solid green",
          },
          confirmEmail: {
            // backgroundColor: "green",
            color: "white",
            border: "1px solid green",
          },
        });

        // Update email button text and style
        setEmailButtonText("EMAIL UPDATED");
        setEmailButtonStyle({
          background: "green",
          color: "black",
        });
      } else if (response.status === 201) {
        toast.error('"Please Login Again', {
          position: "top-center",
          duration: 3000,
        });
        redirect("/login");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error(error.response.data.message, {
          position: "top-center",
          duration: 3000,
        });
        setEmailButtonText("TRY AGAIN");

        // Update styles for wrong email
        setEmailInputStyle({
          backgroundColor: "red",
          color: "white",
        });

        setEmailButtonStyle({
          background: "white",
          color: "black",
        });
      } else {
        console.error(error);
      }
    }
  };

  // Add a state for email button style
  const [emailButtonStyle, setEmailButtonStyle] = useState({
    background: "",
    color: "",
  });

  //=========||Update User Name||------
  const handleUpdateName = async () => {
    const userId = userInfo && userInfo?.userId ? userInfo?.userId : "";
    if (!newFirstName || !newLastName || !userId) {
      setError("All fields must be filled.");

      setTimeout(() => {
        setError("");
      }, 5000);

      return;
    }
    try {
      const response = await api.UpdatePersonalInformationName(
        newFirstName,
        newLastName,
        userId
      );
      if (response.status === 200) {
        setFirstname(newFirstName);
        setLastname(newLastName);

        console.log(response.data.message);
        toast.success(response.data.message, {
          position: "top-center",
          duration: 3000,
        });

        // Update name input styles
        setNameInputStyles({
          firstName: {
            border: "1px solid green", // Set your desired border radius
            // backgroundColor: "green",
            color: "white",
          },
          lastName: {
            border: "1px solid green", // Set your desired border radius
            // backgroundColor: "green",
            color: "white",
          },
        });

        // Update name button text and style
        setNameButtonText("NAME UPDATED");
        setNameButtonStyle({
          background: "green",
          color: "black",
        });
      } else if (response.status === 201) {
        toast.error('"Please Login Again', {
          position: "top-center",
          duration: 3000,
        });
        redirect("/login");
      }
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(error.response.data.message, {
          position: "top-center",
          duration: 3000,
        });
      }
      console.log(error);
    }
  };

  //------------||word||------------------------
  const handleUpdatePassword = async () => {
    
    try {
      if (!currentPassword || !newPassword || !confirmPassword) {
        setError("All fields must be filled.");

        setTimeout(() => {
          setError("");
        }, 5000);

        return;
      }
      if (newPassword.length < 6) {
        setError("New password must be at least 6 characters long.");

        setTimeout(() => {
          setError("");
        }, 5000);

        return;
      }
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/update-password`,
        {
          userId: userData?.user?.userId,
          currentPassword,
          newPassword,
          confirmPassword,
        }
      );

      
      setSuccessMessage(response.data.message);

      if (response.status === 200) {
        setButtonText("NEW PASSWORD SAVED");
        setButtonStyle({
          background: "green",
          color: "black",
        });
      } else if (response.status === 201) {
        toast.error('"Please Login Again', {
          position: "top-center",
          duration: 3000,
        });
        redirect("/login");
      }
      setCurrentPasswordInputStyle({
        background: "initial",
      });
    } catch (err) {
      if (err.response) {
        setError(err.response.data.message);
      } else if (err.request) {
        setError("No response from the server.");
        // Update styles for wrong current password
        setCurrentPasswordInputStyle({
          backgroundColor: "red",
          color: "white",
        });

        setButtonStyle({
          background: "white",
          color: "black",
        });
      } else {
        setError("Error in sending the request.");
      }
      setButtonText("TRY AGAIN");
      setCurrentPasswordInputStyle({
        backgroundColor: "red",
        color: "white",
      });
      setButtonStyle({
        background: "white",
        color: "black",
      });
    }
  };

  const newPasswordInputStyle = {
    backgroundColor: newPassword !== confirmPassword ? "red" : "",
    color: newPassword !== confirmPassword ? "white" : "white",
    border:
      (newPassword === confirmPassword || successMessage) &&
      newPassword.length > 1
        ? "1px solid green"
        : "",
  };

  const [currentPasswordInputStyle, setCurrentPasswordInputStyle] = useState({
    backgroundColor: "",
    color: "",
  });

  const [buttonStyle, setButtonStyle] = useState({
    background: successMessage ? "green" : error ? "white" : "",
    color: successMessage || error ? "black" : "white",
  });

  const [notificationButtonStyle, setNotificationButtonStyle] = useState({
    background: "",
    color: "",
  });

  const [notificationButtonText, setNotificationButtonText] =
    useState("UPDATE");

  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] =
    useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isRePasswordVisible, setIsRePasswordVisible] = useState(false);

  const toggleCurrentPasswordVisibility = () => {
    setIsCurrentPasswordVisible(!isCurrentPasswordVisible);
  };

  const toggleNewPasswordVisibility = () => {
    setIsNewPasswordVisible(!isNewPasswordVisible);
  };

  const toggleRePasswordVisibility = () => {
    setIsRePasswordVisible(!isRePasswordVisible);
  };
  const accountDetails = {
    status: displayStatus(staus),
    plan: displayFrequency(plan),
    nextBilling,
    method,
    card,
  };
  return (
    <div>
      <Facebook />
      <div class="c-tab_cata-text c-my_account-tagline">
        <h4>My ACCOUNT</h4>
      </div>
      <div class=" p-0 m-0 ">
        <ul id="tabs" class="nav nav-tabs c-myaccount_link" role="tablist">
          <li class="nav-item">
            <Link
              onClick={handleShowPersonal}
              style={{
                //backgroundColor: activeTab === 'personal' ? 'green' : 'initial',
                color: activeTab === "personal" ? "#3CB4E4" : "#C4C4C4",
                borderBottomWidth: activeTab === "personal" ? "4px" : "0px",
                borderBottomColor:
                  activeTab === "personal" ? "#3CB4E4" : "#3CB4E4",
                borderBottomStyle: activeTab === "personal" ? "solid" : "solid",
              }}
            >
              ACCOUNT DETAILS
            </Link>
          </li>
          {userData.user.userType !== "subuser" && (
            <li class="nav-item">
              <Link
                onClick={handleShowSubscription}
                style={{
                  // backgroundColor: activeTab === 'subscription' ? 'blue' : 'initial',
                  color: activeTab === "subscription" ? "#3CB4E4" : "#C4C4C4",
                  borderBottomWidth:
                    activeTab === "subscription" ? "4px" : "0px",
                  borderBottomColor:
                    activeTab === "subscription" ? "#3CB4E4" : "#3CB4E4",
                  borderBottomStyle:
                    activeTab === "subscription" ? "solid" : "solid",
                }}
              >
                {userData.user?.userType === "studio-owner"
                  ? "MANAGE STUDIO"
                  : "MANAGE SUBSCRIPTION"}
              </Link>
            </li>
          )}
        </ul>
        <div id="content" class="tab-content" role="tablist">
          {showPersonal && (
            <div
              id="pane-A"
              class="c-myaccount_contrnt"
              role="tabpanel"
              aria-labelledby="tab-A"
            >
              <div className="c-my-ac_headding">
                <h4>
                  Welcome, {firstname} {lastname}!
                </h4>
              </div>
              <div class="c-myaccount_m_list">
                <UploadProfileImage
                  user={userInfo}
                  imageRequiresValidation={imageRequiresValidation}
                  setImageRequiresValidation={(doesIt) => {
                    setImageRequiresValidation(doesIt);
                  }}
                />

                {/* ////////////////////// */}
                <div class="c-myaccount_main_box c-myaccount_main_box2 ">
                  <h4>NAME</h4>
                  <div className="c-my-acc_form_box c-my-acc_form_box-2">
                    <div className="c-my-acc_form_item">
                      <input
                        type="text"
                        className="c-myac_name-input"
                        id="name"
                        name="firstLastName"
                        disabled={true}
                        placeholder={`${firstname} ${lastname}`}
                      />
                    </div>
                    <div className="c-my-acc_form_item">
                      <input
                        type="text"
                        id="newName"
                        name="newName"
                        placeholder="First Name"
                        value={newFirstName}
                        onChange={(e) => setNewFirstName(e.target.value)}
                        style={nameInputStyles.firstName}
                      />
                    </div>
                    <div className="c-my-acc_form_item">
                      <input
                        type="text"
                        id="newLastName"
                        name="newLastName"
                        placeholder="Last Name"
                        value={newLastName}
                        onChange={(e) => setNewLastName(e.target.value)}
                        style={nameInputStyles.lastName}
                      />
                    </div>
                  </div>
                  {/* {error} */}

                  <button
                    className="c-form_my-btn"
                    onClick={handleUpdateName}
                    style={nameButtonStyle}
                  >
                    {nameButtonText}
                  </button>
                </div>
                {/* ////////////////////////////Email //////////////// */}
                <div class="c-myaccount_main_box c-myaccount_main_box2 ">
                  <h4>EMAIL ADDRESS</h4>
                  <div className="c-my-acc_form_box c-my-acc_form_box-2">
                    <div className="c-my-acc_form_item">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="c-myac_name-input"
                        placeholder={email}
                        disabled={true}
                      />
                    </div>
                    {userData.user.userType !== "subuser" && (
                      <>
                        <div className="c-my-acc_form_item">
                          <input
                            type="email"
                            id="newEmail"
                            name="newEmail"
                            placeholder="Enter New Email"
                            value={newEmail}
                            onChange={(e) => setNewEmail(e.target.value)}
                            style={emailInputStyles.newEmail}
                          />
                        </div>
                        <div className="c-my-acc_form_item">
                          <input
                            type="email"
                            id="confirmEmail"
                            name="confirmEmail"
                            placeholder="Confirm New Email"
                            value={confirmEmail}
                            onChange={(e) => setConfirmEmail(e.target.value)}
                            style={emailInputStyles.confirmEmail}
                          />
                        </div>
                      </>
                    )}
                  </div>
                  {/* {error} */}

                  <button
                    className="c-form_my-btn"
                    onClick={handleUpdateEmail}
                    style={emailButtonStyle}
                  >
                    {emailButtonText}
                  </button>
                </div>
                {/* //////////////PASSWORD///////////////////// */}
                <div class="c-myaccount_main_box c-myaccount_main_box2 ">
                  <h4>Change Password</h4>
                  <div className="c-my-acc_form_box c-my-acc_form_box-2">
                    <div
                      className="c-my-acc_form_item"
                      style={{ position: "relative" }}
                    >
                      <input
                        type={isCurrentPasswordVisible ? "text" : "password"}
                        id="currentPassword"
                        name="currentPassword"
                        placeholder="Enter Existing Password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        style={{
                          background:
                            currentPasswordInputStyle.backgroundColor || error
                              ? "red"
                              : "",
                          color:
                            currentPasswordInputStyle.color || error
                              ? "white"
                              : "white",
                          border:
                            currentPasswordInputStyle.border || successMessage
                              ? "1px solid green"
                              : "white",
                        }}
                      />
                      <span
                        className="c-password_visibility--icon"
                        onClick={toggleCurrentPasswordVisibility}
                      >
                        <img
                          src={isCurrentPasswordVisible ? ShowIcon : HideIcon}
                        />
                      </span>
                    </div>
                    <div
                      className="c-my-acc_form_item"
                      style={{ position: "relative" }}
                    >
                      <input
                        type={isNewPasswordVisible ? "text" : "password"}
                        id="newPassword"
                        name="newPassword"
                        placeholder="Create New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        style={newPasswordInputStyle}
                      />
                      <span
                        className="c-password_visibility--icon"
                        onClick={toggleNewPasswordVisibility}
                      >
                        <img src={isNewPasswordVisible ? ShowIcon : HideIcon} />
                      </span>
                    </div>
                    <div
                      className="c-my-acc_form_item"
                      style={{ position: "relative" }}
                    >
                      <input
                        type={isRePasswordVisible ? "text" : "password"}
                        id="confirmPassword"
                        name="confirmPassword"
                        placeholder="Confirm New Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        style={newPasswordInputStyle}
                      />
                      <span
                        className="c-password_visibility--icon"
                        onClick={toggleRePasswordVisibility}
                      >
                        <img src={isRePasswordVisible ? ShowIcon : HideIcon} />
                      </span>
                    </div>
                  </div>
                  {error}

                  <button
                    className="c-form_my-btn"
                    onClick={handleUpdatePassword}
                    style={buttonStyle}
                  >
                    {buttonText}
                  </button>
                </div>

                <div
                  class="c-myaccount_main_box c-myaccount_main_box2 c-myaccount-notifications"
                  style={{
                    justifyContent: "start",
                    gap: "24px",
                  }}
                >
                  <h4>NOTIFICATIONS</h4>
                  <div className="c-my-acc_form_box c-my-acc_form_box-2">
                    <div
                      className={"c-myaccount-notifications-row"}
                      onClick={() => {
                        setCommentNotifications((c) => !c);
                      }}
                    >
                      <div className={"c-myaccount-notifications-label"}>
                        {"Turn On/Off Community Chat Notifications"}
                      </div>
                      <Switch
                        checked={commentNotifications}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </div>
                  </div>

                  <button
                    className="c-form_my-btn"
                    style={notificationButtonStyle}
                    onClick={updateNotifications}
                  >
                    {notificationButtonText}
                  </button>
                </div>
              </div>
            </div>
          )}

          {showSubscription && (
            <div
              id="pane-B"
              class="c-myaccount_contrnt"
              role="tabpanel"
              aria-labelledby="tab-B"
            >
              {userData.user.userType === "studio-owner" && (
                <div className="studio-subscription-heading_1">
                  <h4>MY STUDIO</h4>
                </div>
              )}
              
              <div className="c-mysubscription_subuser_add">
                {userData.user.userType === "studio-owner" && (
                  <SubUserAdd subscriptionData={subscriptionData} setSeatsData={setSeatsData} />
                )}
              </div>
             
              <div class="c-myaccount_m_list c-myaccount_m_list-tab-2">
                {userData.user.userType === "studio-owner" ? (
                  <>
                    <div className="c-myaccount_studio_box">
                      <div className="studio-subscription-heading">
                        <h4>STUDIO SUBCRIPTION ({seatsData} SEATS)</h4>
                      </div>
                      <div className = "studio-myaccount-box">
                      <div className="c-my-acc_form_box_studio">
                        {studioSubscriptionDetails(accountDetails)}
                      </div>
                      <div className="manage-studio-box">
                       
                       <button
                         className="c-form_my-btn"
                         data-cb-type="portal"
                         onClick={() => {
                           window.Chargebee.registerAgain();
                           const testInstance =
                             window?.Chargebee?.getInstance();
                           const cart = testInstance?.getCart();
                           console.log(cart);

                           const customerDetails = {
                             email: userData.user?.email
                               ? email.toLowerCase()
                               : "",
                             first_name: userData.user?.firstname,
                             last_name: userData.user?.lastname,
                           };

                           const fpTid = window.getFPTid();
                           if (fpTid) {
                             customerDetails.cf_tid = fpTid;
                           }

                           cart.setCustomer(customerDetails);
                         }}
                       >
                         MANAGE STUDIO SUBSCRIPTION
                       </button>
                     </div>

                      </div>
                      
                     
                    </div>
                  </>
                ) : (
                  <div class="c-myaccount_main_box">
                    <h4>MY SUBSCRIPTION</h4>
                    <div class="c-my-acc_form_box">
                      <div class="c-my-acc_form_item">
                        <span class="span1">Account Status:</span>
                        <span class="span2">{displayStatus(staus)}</span>
                      </div>
                      <div class="c-my-acc_form_item">
                        <span class="span1">Current Plan:</span>
                        <span class="span2 c-span-icon">
                          {displayFrequency(plan)}
                        </span>
                      </div>

                      <div class="c-my-acc_form_item">
                        <span class="span1">Next Billing Date:</span>
                        <span class="span2 c-span-icon">{nextBilling}</span>
                      </div>
                      <div class="c-my-acc_form_item m-0">
                        <span class="span1">Payment Method:</span>
                        <span class="span2">
                          {" "}
                          {method ? `${method} ending` : " "} {card}
                        </span>
                      </div>
                      <div class="c-my-acc_form_item-la">
                        <a href="javascript:void(0)" data-cb-type="portal">
                          Update Payment Method
                        </a>
                      </div>
                    </div>
                    {/* <a href="javascript:void(0)" data-cb-type="portal"> */}
                    <button
                      className="c-form_my-btn"
                      data-cb-type="portal"
                      onClick={() => {
                        window.Chargebee.registerAgain();
                        const testInstance = window?.Chargebee?.getInstance();
                        const cart = testInstance?.getCart();
                        console.log(cart);

                        const customerDetails = {
                          email: userData.user?.email
                            ? userData.user.email.toLowerCase()
                            : "",
                          first_name: userData.user?.firstname,
                          last_name: userData.user?.lastname,
                        };

                        const fpTid = window.getFPTid();
                        if (fpTid) {
                          customerDetails.cf_tid = fpTid;
                        }

                        cart.setCustomer(customerDetails);
                      }}
                    >
                      MANAGE SUBSCRIPTION
                    </button>
                    {/* </a> */}
                    {/* <div class="c-subscription_item">
                                     <a href="javascript:void(0)" data-cb-type="portal">CANCEL SUBSCRIPTION</a>
                                 </div> */}
                  </div>
                )}
              </div>
            
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function studioSubscriptionDetails(accountDetails) {
  return (
    <div className="studio-subscription-details">
      <div className="container">
        <div>
          <p className="label">Account Status: </p>{" "}
          <span className="value"> {accountDetails.status}</span>
        </div>
        <div>
          <p className="label">Current Plan: </p>{" "}
          <span className="value">{accountDetails.plan}</span>
        </div>
      </div>
      <div className="container">
        <div>
          <p className="label">Next Billing Date: </p>{" "}
          <span className="value">{accountDetails.nextBilling}</span>
        </div>
        <div>
          <p className="label">Payment Method:</p>{" "}
          <span className="value">
            {accountDetails.method} {accountDetails.card}
          </span>
        </div>
      </div>
    </div>
  );
}

export default MyAccount;
