import React, { useEffect } from "react";
import axios from "axios";
import { useAtomValue, useSetAtom } from "jotai";
import { userInfoAtom } from "../data/atoms/utilsAtoms";
import ChargbeeButton from "./PlanPage/ChargbeeButton";
import checkApiStatus from "./SubscripStatus";

function PlanPage() {
  const userData = useAtomValue(userInfoAtom);
  const setUserData = useSetAtom(userInfoAtom)

  useEffect(() => {
    const intervalId = setInterval(() => {
      checkApiStatus(userData.token, setUserData);
    }, 5000); // 5000 milliseconds = 5 seconds
  
    // Cleanup function to clear the interval when the component is unmounted or dependencies change
    return () => clearInterval(intervalId);
  }, [setUserData, userData.user?.toString(), userData.token]);

  axios.defaults.withCredentials = true;

  return (
    <div>
      {/* Mobile  */}

      <div className="row m-0 c-mobile_honm-serch">
        <div className="c-header_search">
          <form action="">
            <input type="text" id="search" placeholder="Search" />
            <button id="search-button" aria-label="search button">
              <span className="c_header-mac">
                <i class="fa-solid fa-microphone"></i>
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
              >
                <path
                  d="M17.8516 17.5L14.2266 13.875M16.1849 9.16667C16.1849 12.8486 13.2001 15.8333 9.51823 15.8333C5.83633 15.8333 2.85156 12.8486 2.85156 9.16667C2.85156 5.48477 5.83633 2.5 9.51823 2.5C13.2001 2.5 16.1849 5.48477 16.1849 9.16667Z"
                  stroke="#71717A"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </form>
        </div>
      </div>
      {/* /////////////////// */}
      <div className="container-c-section">
        {userData.token ? (
          <div className="c-join-section">
            {userData.token
              ? `Welcome, ${userData.user.firstname} ${userData.user.lastname}.`
              : ""}
          </div>
        ) : (
          <div className="c-join-section">
            Join The Community and Access Exclusive Content
          </div>
        )}
      </div>
      <div class="c-tab_cata-text c-plane_page-m">
        <h4>Pick your plan</h4>
      </div>
      {/* ///////////////////// */}
      <div className="c-plan_section">
        <div className="c-video_textcol">
          <h3 className="c-plane-mobile_h3">Pick your Plan</h3>
          <h4 className="c-plane-mobile-h4">Pick your Plan</h4>
        </div>
        <div className="c-plan_list">
          <div className="c-plan_new-one">
            <div>
              <h3 className="c-plan-new-offer">Save 37%</h3>
              <div className="c-plan_pric-box">
                <h4>ANNUAL PLAN</h4>
                <h2>
                  $12.49<span>/month</span>
                </h2>
                <h5>$149.99 Billed Annually</h5>
                <p>Access our full video library</p>
                <ChargbeeButton planType={"yearly"} newlySignedUp={false} isStudio={false} />
              </div>
            </div>
          </div>
          <div className="c-plan_new-two">
            <div>
              <div>
                <h4>Monthly PLAN</h4>
                <h2>
                  $19.99/<span>month</span>
                </h2>
                <p>Access our full video library</p>
                <ChargbeeButton planType="monthly" newlySignedUp={false} isStudio={false} />
              </div>
            </div>
          </div>
        </div>
        <div className="studio-tagline">
          <span>Interested in a Studio Account?</span>{" "}
          <span className="studio-tag">
            <a
              href="https://thegodfreymethod.zendesk.com/hc/en-us/categories/33430471739027-Studio-Accounts"
              target="_blank"
            >
              Studio Account Information
            </a>
          </span>
          <span className="studio-tag">
            <a href="/studioPlans">Sign Up</a>
          </span>
        </div>
      </div>
    </div>
  );
}

export default PlanPage;
