import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import { FaHome, FaHeart, FaUser, FaFacebook, FaHeadset } from "react-icons/fa";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import { MdRequestPage } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import checkApiStatus from "../../component/SubscripStatus";
import { useAtomValue, useSetAtom } from "jotai";
import { userInfoAtom } from "../../data/atoms/utilsAtoms";
import { SocialMediaLinks } from "../../constants/SocialMediaLinks";

function Sidebar({ closeCallback }) {
  const [apistatus, setApistatus] = useState(true);

  const userData = useAtomValue(userInfoAtom);
  const setUserData = useSetAtom(userInfoAtom);

  const [showListMenu, setShowListMenu] = useState(false);

  const location = useLocation();
  const path = location.pathname;

  const [clickedTags, setClickedTags] = useState({
    home: false,
    library: false,
    exercises: false,
    lesson: false,
    across: false,
    // follow_along:false,
    favourites: false,
    playlists: false,
    featured: false,
    personal: false,
    history: false,
    account: false,
    community: false,
    help: false,
  });
  const [openSubMenus, setOpenSubMenus] = useState([]);
  
  useEffect(() => {
    const findData = async () => {
      const apistatus = await checkApiStatus(userData.token, setUserData);
      setApistatus(apistatus);
    };
    findData();

    if (
      path === "/all-playlist" ||
      path === "/featured-playlist" ||
      path === "/personal-playlist"
    ) {
      setShowListMenu(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apistatus, path, userData.token, setUserData]);

  const toggleSubMenu1 = (index) => {
    if (openSubMenus.includes(index)) {
      setOpenSubMenus(openSubMenus.filter((item) => item !== index));
    } else {
      setOpenSubMenus([...openSubMenus, index]);
    }
  };

  const toggleSubMenu = (tag) => {
    // Create a copy of the current clickedTags state
    const updatedTags = { ...clickedTags };

    // Reset the color of the previously clicked tab
    for (const key in updatedTags) {
      updatedTags[key] = false;
    }
    // Set the new tab as active
    updatedTags[tag] = true;
    setClickedTags(updatedTags);
  };

  const isActive = (tag) => (clickedTags[tag] ? "active" : "");
  const getBackgroundColor = (name) => {
    return location.pathname.includes(name)
      ? { backgroundColor: "#171718", borderRight: "0px solid #3c3c3c" }
      : {};
  };

  const getColor = (name) => {
    return location.pathname.includes(name) ? "#0B6386" : "#C4C4C4";
  };

  const [library, setLibrary] = useState(false);

  return (
    <div>
      <ul id="toggle" className="nav nav-pills nav-flush flex-column mb-auto">
        <li className={`nav-item ${isActive("home")}`}>
          <Link
            to="/home"
            onClick={() => {
              toggleSubMenu("home");
              closeCallback();
            }}
            style={getBackgroundColor("home")}
          >
            <div className={`custom-home-icon ${isActive("home")}`}>
              <FaHome style={{ color: getColor("home") }} />
            </div>
            <span style={{ color: getColor("home") }}>HOME</span>
          </Link>
        </li>
        <li className={`nav-item ${isActive("library")}`}>
          <div className="position-relative">
            <Link
              to="/Library"
              onClick={() => {
                toggleSubMenu("library");
                toggleSubMenu1(1);
                setLibrary(true);
              }}
              style={getBackgroundColor("Library")}
            >
              <div className={`custom-home-icon ${isActive("library")}`}>
                <VideoLibraryIcon
                  style={{
                    color: getColor("Library"),
                  }}
                />
              </div>
              <span style={{ color: getColor("Library") }}>LIBRARY</span>
            </Link>
            <button
              type="button"
              onClick={() => setLibrary(!library)}
              className="btn border-0 position-absolute top-0 end-0 collapse__btn"
            >
              <i
                className={library ? "fa-solid fa-minus" : "fa-solid fa-plus"}
              ></i>
            </button>
          </div>
          {library && (
            <ul className={`sub-menu ${clickedTags.all ? "" : ""}`}>
              <li>
                <Link
                  to="/Lessons"
                  onClick={() => {
                    toggleSubMenu("lesson");
                    closeCallback();
                  }}
                  style={getBackgroundColor("Lessons")}
                >
                  <i
                    className="fa-solid fa-minus"
                    style={{
                      color: getColor("Lessons"),
                    }}
                  ></i>
                  <span
                    style={{
                      color: getColor("Lessons"),
                    }}
                  >
                    Lessons
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/Exercises"
                  onClick={() => {
                    toggleSubMenu("exercises");
                    closeCallback();
                  }}
                  style={getBackgroundColor("Exercises")}
                >
                  <i
                    className="fa-solid fa-minus"
                    style={{
                      color: getColor("Exercises"),
                    }}
                  ></i>
                  <span
                    style={{
                      color: getColor("Exercises"),
                    }}
                  >
                    Exercises
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/Acrossthefloor"
                  onClick={() => {
                    toggleSubMenu("across");
                    closeCallback();
                  }}
                  style={getBackgroundColor("Acrossthefloor")}
                >
                  <i
                    className="fa-solid fa-minus"
                    style={{
                      color: getColor("Acrossthefloor"),
                    }}
                  ></i>
                  <span
                    style={{
                      color: getColor("Acrossthefloor"),
                    }}
                  >
                    Across The Floor
                  </span>
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/FollowAlong"
                  onClick={() => {
                    toggleSubMenu("follow_along");
                    closeCallback();
                  }}
                  style={getBackgroundColor("FollowAlong")}
                >
                  <i
                    className="fa-solid fa-minus"
                    style={{
                      color: getColor("FollowAlong"),
                    }}
                  ></i>
                  <span
                    style={{
                      color: getColor("FollowAlong"),
                    }}
                  >
                    Follow Along
                  </span>
                </Link>
              </li> */}
              <li>
                <Link
                  to="/AllVideos"
                  onClick={() => {
                    toggleSubMenu("all");
                    closeCallback();
                  }}
                  style={getBackgroundColor("AllVideos")}
                >
                  <i
                    className="fa-solid fa-minus"
                    style={{
                      color: getColor("AllVideos"),
                    }}
                  ></i>
                  <span
                    style={{
                      color: getColor("AllVideos"),
                    }}
                  >
                    View All
                  </span>
                </Link>
              </li>
            </ul>
          )}
        </li>

        <li className={`nav-item ${isActive("playlist")}`}>
          <div className="position-relative">
            <Link
              to="/all-playlist"
              onClick={() => {
                toggleSubMenu("playlist");
                toggleSubMenu1(2);
                setShowListMenu(true);
              }}
              className="collapse__btn"
              style={getBackgroundColor("all-playlist")}
            >
              <div className={`custom-home-icon ${isActive("playlist")}`}>
                <VideoLibraryIcon
                  style={{
                    color: getColor("all-playlist"),
                  }}
                />
              </div>
              <span style={{ color: getColor("all-playlist") }}>Playlists</span>
            </Link>

            {/* <button className="btn position-absolute top-0 start-0 w-100 h-100 opacity-0" type="button"></button> */}
            <button
              type="button"
              onClick={() => setShowListMenu(!showListMenu)}
              className="btn border-0 position-absolute top-0 end-0 collapse__btn"
            >
              <i
                className={
                  showListMenu ? "fa-solid fa-minus" : "fa-solid fa-plus"
                }
              ></i>
            </button>
          </div>
          {showListMenu && (
            <ul className={`sub-menu`}>
              <li>
                <Link
                  to="/featured-playlist"
                  onClick={() => {
                    toggleSubMenu("featured");
                    closeCallback();
                  }}
                  style={getBackgroundColor("featured-playlist")}
                  className={path === "/featured-playlist" ? "active-link" : ""}
                >
                  <i
                    className={
                      path === "/featured-playlist"
                        ? "fa-solid fa-minus"
                        : "fa-solid fa-minus"
                    }
                    style={{
                      color: getColor("featured-playlist"),
                    }}
                  ></i>
                  <span
                    style={{
                      color: getColor("featured-playlist"),
                    }}
                  >
                    Featured Playlists
                  </span>
                </Link>
              </li>
              {userData.token && (
                <li>
                  <Link
                    to="/personal-playlist"
                    onClick={() => {
                      toggleSubMenu("personal");
                      closeCallback();
                    }}
                    style={getBackgroundColor("personal-playlist")}
                    className={
                      path === "/personal-playlist" ? "active-link" : ""
                    }
                  >
                    <i
                      className={
                        path === "/personal-playlist"
                          ? "fa-solid fa-minus"
                          : "fa-solid fa-minus"
                      }
                      style={{
                        color: getColor("personal-playlist"),
                      }}
                    ></i>
                    <span
                      style={{
                        color: getColor("personal-playlist"),
                      }}
                    >
                      Personal Playlists
                    </span>
                  </Link>
                </li>
              )}
            </ul>
          )}
        </li>

        {userData.token ? (
          <li className={`nav-item ${isActive("favourites")}`}>
            <Link
              to="/saved"
              onClick={() => toggleSubMenu("favourites")}
              style={getBackgroundColor("saved")}
            >
              <div className={`custom-home-icon ${isActive("favourites")}`}>
                <FaHeart
                  style={{
                    color: getColor("saved"),
                  }}
                />
              </div>
              <span
                style={{
                  color: getColor("saved"),
                }}
              >
                SAVED
              </span>
            </Link>
          </li>
        ) : (
          <li className={`nav-item ${isActive("favourites")}`}>
            <Link
              to="/SignUp"
              onClick={() => toggleSubMenu("favourites")}
              style={getBackgroundColor("saved")}
            >
              <div className={`custom-home-icon ${isActive("favourites")}`}>
                <FaHeart
                  style={{
                    color: getColor("saved"),
                  }}
                />
              </div>
              <span
                style={{
                  color: getColor("saved"),
                }}
              >
                SAVED
              </span>
            </Link>
          </li>
        )}
        {!apistatus && userData?.user?.userType !== "subuser" && (
          <li className={`nav-item ${isActive("planpage")}`}>
            <Link
              to={
                userData.token? 
                userData?.user?.userType === "studio-owner"
                  ? "/studioPlans"
                  : "/Plans"
                  : "/SignUp"
              }
              onClick={() => {
                toggleSubMenu("planpage");
                closeCallback();
              }}
              style={getBackgroundColor("Plans")}
            >
              <div className={`custom-home-icon ${isActive("planpage")}`}>
                <MdRequestPage
                  style={{
                    color: getColor("Plans"),
                  }}
                />
              </div>
              <span style={{ color: getColor("Plans") }}>PLANS</span>
            </Link>
          </li>
        )}

        {userData?.token && (
          <li className={`nav-item ${isActive("account")}`}>
            <Link
              to="/account"
              onClick={() => {
                toggleSubMenu("account");
                closeCallback();
              }}
              style={getBackgroundColor("account")}
            >
              <div className={`custom-home-icon ${isActive("account")}`}>
                <FaUser
                  style={{
                    color: getColor("account"),
                  }}
                />
              </div>
              <span style={{ color: getColor("account") }}>MY ACCOUNT</span>
            </Link>
          </li>
        )}
      </ul>
      {userData.token && (
        <ul
          id="toggle"
          className="nav nav-pills nav-flush flex-column mb-auto c-login_manu"
        >
          {apistatus && (
            <li className={`nav-item ${isActive("community")}`}>
              <a
                href={SocialMediaLinks.facebook}
                onClick={() => toggleSubMenu("community")}
                style={getBackgroundColor("community")}
                target="_BLANK"
              >
                <div className={`custom-home-icon ${isActive("community")}`}>
                  <FaFacebook
                    style={{ color: clickedTags.home ? "#71717A" : "#71717A" }}
                  />
                </div>
                <span
                  style={{ color: clickedTags.home ? "#71717A" : "#71717A" }}
                >
                  Community
                </span>
              </a>
            </li>
          )}

          <li className={`nav-item ${isActive("help")}`}>
            <a
              href={"https://thegodfreymethod.zendesk.com/hc/en-us"}
              onClick={() => toggleSubMenu("help")}
              style={getBackgroundColor("help")}
              target="_BLANK"
            >
              <div className={`custom-home-icon ${isActive("help")}`}>
                <FaHeadset
                  style={{
                    color: clickedTags.favourites ? "#71717A" : "#71717A",
                  }}
                />
              </div>
              <span
                style={{
                  color: clickedTags.favourites ? "#71717A" : "#71717A",
                }}
              >
                Help & Support
              </span>
            </a>
          </li>
        </ul>
      )}
    </div>
  );
}

export default Sidebar;
