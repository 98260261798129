import { useState, useEffect } from "react";
import { userInfoAtom } from "../../data/atoms/utilsAtoms";
import { useAtomValue } from "jotai";
import { useNavigate } from "react-router-dom";
import Facebook from "../Facebook";
import { userPlans, studioPlans } from "../../data/utils/plans";
import React from "react";

const ChargebeeButton = ({ planType, seats, newlySignedUp, isStudio }) => {
  const [isVisible, setIsVisible] = useState(false);
  const userData = useAtomValue(userInfoAtom);
  const navigate = useNavigate();

  const plans = isStudio ? studioPlans : userPlans;

  const selectedPlan = isStudio
    ? plans.find((plan) => plan.seats === seats)
    : plans.find((plan) => plan.param === planType);

  console.log(selectedPlan?.cbItem);
  console.log("isStudio condition", isStudio);

  const handleChargebeeClick = (type, isStudio) => {
    if (userData.token == null) {
      localStorage.clear();
    }

    if (userData.token != null || newlySignedUp) {
      window.Chargebee.registerAgain();
      window?.fbq("trackCustom", selectedPlan.id, {
        CustomEvent: selectedPlan.id,
      });
    } else {
      if (isStudio) {
        console.log("Studio condition checking");
        navigate("/StudioSignUp");
      } else {
        navigate(`/signup`);
      }
    }
  };
  // Delay rendering by 1.5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 1500);

    return () => clearTimeout(timer); // Cleanup on component unmount
  }, []);

  if (!isVisible || !userData.user?.email) {
    return null; // Render nothing until 1.5 seconds have passed
  }

  return (
    <React.Fragment>
      <Facebook />
      {userData.user?.isTrialAvailed ? (
        <React.Fragment>
          <button
            className="c-form_my-btn"
            data-cb-type="portal"
            onClick={() => {
              window.Chargebee.registerAgain();
              const testInstance = window?.Chargebee?.getInstance();
              const cart = testInstance?.getCart();
              console.log(cart);

              const customerDetails = {
                id: userData.user?.email ? userData.user?.email : "",
                email: userData.user?.email
                  ? userData.user?.email.toLowerCase()
                  : "",
                first_name: userData.user?.firstname,
                last_name: userData.user?.lastname,
              };

              const fpTid = window.getFPTid();
              if (fpTid) {
                customerDetails.cf_tid = fpTid;
              }

              cart.setCustomer(customerDetails);
            }}
          >
            MANAGE SUBSCRIPTION
          </button>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <button
            id={selectedPlan.id}
            style={{ width: "100%" }}
            data-cb-type="checkout"
            data-cb-item-0={selectedPlan.cbItem}
            onClick={() => handleChargebeeClick(planType, isStudio)}
          >
            {!isStudio ? (
              <div>FREE {selectedPlan.days} DAY TRIAL</div>
            ) : (
              <div>Get your plan</div>
            )}
          </button>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ChargebeeButton;
