import axios from "axios";

const cache = {
  data: null,
  timestamp: null,
};

const CACHE_DURATION = 0.1 * 100; // 30 sec in milliseconds

const checkApiStatus = async (token = "", setUserData = null) => {
  axios.defaults.withCredentials = true;

  // Helper function to handle 401 response
  const handleUnauthorized = () => {
    localStorage.clear();
    window.location.reload();
  };

  // Check if cached data is still valid (within 30 minutes)
  const currentTime = new Date().getTime();
  if (cache.data && cache.timestamp && currentTime - cache.timestamp < CACHE_DURATION) {
    console.log("Using cached API data");
    const response = cache.data;

    if (setUserData != null) {
      setUserData((prevUserData) => ({
        ...prevUserData,
        user: {
          ...prevUserData.user,
          isTrialAvailed: response?.data?.isTrialAvailed ?? false,
        },
      }));
    }

    return handleApiResponse(response);
  }

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/fetch/subscribtion`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    console.log("Fetching new API data", response);

    // Check for 401 status in response
    if (response.status === 401) {
      handleUnauthorized();
      return false;
    }

    // Update the cache
    cache.data = response;
    cache.timestamp = currentTime;

    if (setUserData != null) {
      setUserData((prevUserData) => ({
        ...prevUserData,
        user: {
          ...prevUserData.user,
          isTrialAvailed: response?.data?.isTrialAvailed ?? false,
        },
      }));
    }

    return handleApiResponse(response);
  } catch (error) {
    console.error("Error calling API:", error);

    // Check for 401 status in error response
    if (error.status === 401) {
      handleUnauthorized();
      return false;
    }
    return false;
  }
};

const handleApiResponse = (response) => {
  if (response.status === 401) {
    localStorage.clear();
    window.location.reload();
    return false;
  }

  if (response.status === 200) {
    const { data } = response;
    const { subData, data: subscriptionStatus } = data;

    localStorage.setItem("subscriptionId", subData?.SubscribtionId ?? "");

    if (subscriptionStatus === "in_trial" || subscriptionStatus === "active" || subscriptionStatus === "non_renewing") {
      return true;
    }
  }
  return false;
};

export default checkApiStatus;
