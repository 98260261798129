import { atom } from "jotai";

const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null;
const user = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : null;
const role = localStorage.getItem("role") ? JSON.parse(localStorage.getItem("role")) : null;
const subscriptionId = localStorage.getItem("subscriptionId")

export const loaderState = atom(false);

export const userInfoAtom = atom({
    token: token || null,
    user: user || null,
    role: role || null,
    subscriptionId: subscriptionId || null
  });