import React from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import Logo from "../../../Asset/images/logo-blaxk.png";

const navigationItems = [
  { path: "/AdminDashboard/AllVideos", text: "All Videos" },
  { path: "/AdminDashboard/AddVideos", text: "Add Videos" },
  // { path: "/AdminDashboard/AddCategory", text: "Add Category" },
  { path: "/AdminDashboard/TagManagement", text: "Tag Management" },
  { path: "/AdminDashboard/AllUsers", text: "All Users" },
  { path: "/AdminDashboard/featured-playlist", text: "Featured Playlists" },
  { path: "/AdminDashboard/moderation", text: "Moderation" },
  { path: "/AdminDashboard/notification", text: "Notification" },
  { path: "/AdminDashboard/banners", text: "Banner" },
];

export default function SideBar() {
  return (
    <div className="main-menu menu-fixed menu-accordion menu-shadow expanded menu-light">
      <div className="navbar-header">
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item me-auto mb-2">
            <Link to="/AdminDashboard" className="navbar-brand m-0">
              <span className="c-brand-logo">
                <img src={Logo} alt="" srcSet="" />
              </span>
            </Link>
          </li>
        </ul>
      </div>
      <ul className="navigation navigation-main">
        {/* Map over navigationItems to generate navigation links */}
        {navigationItems.map((item, index) => (
          <li key={index} className="nav-item">
            <NavLink to={item.path} className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" y1="16" x2="12" y2="12"></line>
                <line x1="12" y1="8" x2="12.01" y2="8"></line>
              </svg>
              {item.text}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
