import React, { useState, useEffect, useRef, useContext } from "react";
import "../../App.css";
import Filter from "../../Asset/images/icon/Filters-lines.svg";
import Thumbnail from "../../Asset/images/Thumbnail.png";
import Video_title from "../Video_title";
import Card from "../Card";
import axios from "axios";
import { VideoContext } from "../Video_player/VideoContext";
import FilterModal from "../FilterModal";
import { useNavigate } from "react-router-dom";
import checkApiStatus from "../SubscripStatus";
import CustomPagination from "../Pagination/CustomPagination";
import Cookies from "js-cookie";
import { IoMdHeartEmpty } from "react-icons/io";
import { FcLike } from "react-icons/fc";
import { useSelector } from "react-redux";
import MobileViewSearch from "../MobileViewSearch";
import PlaylistAddRoundedIcon from "@mui/icons-material/PlaylistAddRounded";
import AddToPlayListModal from "../playlist/personal-playlist/AddToPlayListModal";
import toast from "react-hot-toast";
import api from "../../data/api";
import { useAtomValue } from "jotai";
import { userInfoAtom } from "../../data/atoms/utilsAtoms";
import { useFetchActiveBanner } from "../../data/swrs/banners";
import videoApi from "../../api/videoApi";

const Exercise = () => {
  const [responseData, setResponseData] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [likedStates, setLikedStates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const [totvideos, setTotVideos] = useState("");
  const [startIndex, setStartIndex] = useState(0);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedDurations, setSelectedDurations] = useState([]);
  const [selectedInstructors, setSelectedInstructors] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [selectedLevels, setselectedLevels] = useState([]);
  const [tags, setTags] = useState([]);
  const [sortby, setSortBy] = useState("");
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  //const categoryId="ObjectId('6541e9cdf1f99641d644a357')";
  const [modal, setModal] = useState(false);
  const userInfo = useSelector((state) => state.authState.userInfo);
  const [localStorageData, setLocalStorageData] = useState(null);
  const [videos, setVideos] = useState("");
  const { selectedVideo, setSelectedVideo } = useContext(VideoContext);
  const [videoData, setVideoData] = useState({});
  const [videoData2, setVideoData2] = useState({});

  const [savedVideoIds, setSavedVideoIds] = useState([])
  const userData = useAtomValue(userInfoAtom)

  useEffect(() => {
    if (userData?.token) {
      videoApi.getAllSavedVideoIds(userData.user.userId)
        .then((res) => {
          setSavedVideoIds(res.data.savedVideos)
        })
    }
  }, [userData.token, responseData])

  useEffect(() => {
    const token = userData.token
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [userData.token]);

  // function showVideo(url, title, desc, tags, thumbnail, products, _id) {
  //   setSelectedVideo({ url, title, desc, tags, thumbnail, products, _id });
  //   redirect(`/PlayerPage?v=${_id}`);
  // }

  // ----------| Add TO Playlist |--------------
  const handelSubmitPlaylist = async (_id) => {
    //setModal(true)
    try {
      const response = await api.videoPlayerPage(_id);
      if (response.status === 200) {
        setModal(false);
        const jsonData = response.data[0];
        setVideoData2(jsonData);
        setModal(true);
      }
    } catch (error) {
      setModal(false);
      toast.error("Something went wrong", {
        position: "top-center",
        duration: 3000,
      });
    }
  };
  useEffect(() => {
    const data = window.localStorage.getItem("selectedVideo");

    if (data) {
      setVideoData(JSON.parse(data));
    }
  }, []);

  useEffect(() => {
    if (selectedVideo) {
      setVideoData(selectedVideo);
    }
  }, [selectedVideo]);

  const handleFilter = () => {
    // Call backend API with selected tags and update the video list
    setIsOpen(!isOpen);
    fetchVideosByTags(selectedTags, selectedDurations, selectedLevels);
  };

  const categoryId = "6541e9f2f1f99641d644a35a";

  const redirect = useNavigate();

  // Function to handle page change in pagination
  // Function to handle page change in pagination
  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    console.log(newPage);
    if (newPage <= 0) {
      newPage = 1;
    }
    setCurrentPage(newPage);
    const newStartIndex = (newPage - 1) * itemsPerPage;
    setStartIndex(newStartIndex);
  };

  const handleSubmit = async (id, video) => {
    console.log(id);
    const isLiked = video.videoDetails?.savedvideo || video.savedvideo || (savedVideoIds?.length > 0 && savedVideoIds?.includes(video?.videoDetails?._id || video?._id));

    try {
      if (isLiked) {
        const response = await api.unsaveWishlist(id);
        console.log(response.data);
        const uniqueIdentifier = id;
        if (response.status === 200) {
          const updatedResponseData = responseData.map((item) => {
            const updatedVideos = item.videos.map((video) => {
              if (video?.videoDetails?._id === uniqueIdentifier) {
                return {
                  ...video,
                  videoDetails: {
                    ...video.videoDetails,
                    savedvideo: false, // Set savedvideo to false for the matched video
                  },
                };
              } else if (video?._id === uniqueIdentifier) {
                return {
                  ...video,
                  savedvideo: false, // Set savedvideo to false for the matched video
                };
              }
              return video;
            });
            return {
              ...item,
              videos: updatedVideos,
            };
          });

          // Update the state (setResponseData) with the updated responseData
          setResponseData(updatedResponseData);
        }
      } else {
        const response = await api.saveWishlist(id);
        if (response.status === 200) {
          console.log(response.data);
          const uniqueIdentifier = id;
          const updatedResponseData = responseData.map((item) => {
            const updatedVideos = item.videos.map((video) => {
              if (video?.videoDetails?._id === uniqueIdentifier) {
                return {
                  ...video,
                  videoDetails: {
                    ...video.videoDetails,
                    savedvideo: true, // Set savedvideo to false for the matched video
                  },
                };
              } else if (video?._id === uniqueIdentifier) {
                return {
                  ...video,
                  savedvideo: true, // Set savedvideo to false for the matched video
                };
              }
              return video;
            });
            return {
              ...item,
              videos: updatedVideos,
            };
          });

          setResponseData(updatedResponseData);
        }
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  //--------for video player page------
  const handlePlayerVideo = async (id) => {
    const apistatus = await checkApiStatus(userData.token);
    if (isLoggedIn) {
      if (apistatus) {
        // User is logged in and subscribed, redirect to PlayerPage
        console.log("video is is -----", id);
        redirect(`/PlayerPage?v=${id}`);
      } else {
        // User is logged in but not subscribed, redirect to PlanPage
        redirect("/Plans");
      }
    } else {
      // User is not logged in, redirect to SignUp
      redirect("/SignUp");
    }
  };

  const handleTagChange = (tag) => {
    setSelectedTags((prevTags) =>
      prevTags.includes(tag)
        ? prevTags.filter((prevTag) => prevTag !== tag)
        : [...prevTags, tag]
    );
  };

  useEffect(() => {
    fetchVideosByTags(selectedTags, selectedDurations, selectedLevels, selectedInstructors);
  }, [
    currentPage,
    itemsPerPage,
    categoryId,
    startIndex,
    selectedTags,
    selectedDurations,
    selectedLevels,
    sortby,
    selectedInstructors,
  ]);

  const handleFilterChange = (event) => {
    setSortBy(event.target.value);
    //fetchVideosByTags(selectedTags, selectedDurations, selectedLevels);
  };

  const fetchData = async (selectedTags, selectedDurations, selectedLevels, selectedInstructors) => {
    try {
      console.log("TAGS ID SELECTED IN EXERCISE", selectedTags);
      const userId = userInfo && userInfo.userId ? userInfo.userId : "";
      const endIndex = startIndex + itemsPerPage - 1;
      const selectedTagsIds = selectedTags?.map((tag) => tag).join(",");
      const durations = selectedDurations
        ?.map((duration) => duration)
        .join(",");
      const levels = selectedLevels?.map((level) => level).join(",");

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/library/single/page/video`, {
        categoryId,
        startIndex,
        endIndex,
        selectedTags: selectedTagsIds,
        selectedDurations: durations,
        selectedLevels: levels,
        sortby: sortby || "latest",
        selectedInstructors: selectedInstructors,
        userId,
      });

      setTotVideos(
        Math.ceil(
          response.data.libraryVideosData[0].totalRecords / itemsPerPage
        )
      );
      setResponseData([]);
      console.log(
        "response after clearing response",
        response.data.libraryVideosData
      );
      setResponseData(response.data.libraryVideosData);

      console.log("Fetched Data:", response.data.libraryVideosData);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    if (responseData?.length > 0 && instructors.length === 0) {
      console.log("Fetched video data from instructor useEffect:", responseData);
      setInstructors([
        ...new Set(
            responseData[0]?.videos
          .filter(video => video.instructor !== undefined && video.instructor !== null)
          .map(video => video.instructor)
        )
      ])
    }
  }, [responseData])

  const fetchVideosByTags = async (
    selectedTags,
    selectedDurations,
    selectedLevels,
    selectedInstructors
  ) => {
    try {
      fetchData(selectedTags, selectedDurations, selectedLevels, selectedInstructors);
    } catch (error) {
      console.error("Error fetching filtered videos: ", error);
    }
  };

  const handleShuffle = (categoryId) => {
    setResponseData((prevData) => {
      // Find the category to shuffle
      const shuffledCategory = prevData.find(
        (category) => category._id === categoryId
      );

      // Shuffle the videos in the category
      if (shuffledCategory) {
        shuffledCategory.videos = shuffleArray(shuffledCategory.videos);
      }

      return [...prevData];
    });
  };
  const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  };
  useEffect(() => {
    // Add event listener to detect clicks outside c-filter_btn-one
    const handleClickOutside = (event) => {
      const filterBtn = document.querySelector(".unique");
      if (filterBtn && !filterBtn.contains(event.target)) {
        setIsOpen(false);
      }
    };
    // Attach the event listener
    document.addEventListener("click", handleClickOutside);
    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleOpenFilterModal = () => {
    setIsFilterModalOpen(true);
  };

  const handleCloseFilterModal = () => {
    setIsFilterModalOpen(false);
  };

  const handleApplyFilter = (
    selectedTags,
    selectedDurations,
    selectedLevels,
    selectedInstructors
  ) => {
    setSelectedTags(selectedTags);
    setselectedLevels(selectedLevels);
    setSelectedDurations(selectedDurations);
    setSelectedInstructors(selectedInstructors);
    //fetchVideosByTags(selectedTags, selectedDurations, selectedLevels);
    setIsFilterModalOpen(false);
  };

  const handleResetFilter = () => {
    setSelectedTags([]);
  };

  return (
    <>
      {/* ///////////////////////////////////////////////Mobile */}
      <div id="c-mobile_search">
        <div class="row m-0 c-tab_box">
          <div class="p-0">
            <div className="c-filter_btn">
              <MobileViewSearch />
              <div className="c-filter_btn-one c-mobile_filter_btn1">
                <img src={Filter} onClick={handleOpenFilterModal} alt="" />
                <span className="filterby-2">
                  {isFilterModalOpen && (
                    <FilterModal
                      isOpen={isFilterModalOpen}
                      tags={tags}
                      checkedTags={selectedTags}
                      checkedDuration={selectedDurations}
                      checkedLevels={selectedLevels}
                      handleTagChange={handleTagChange}
                      handleApplyFilter={handleApplyFilter}
                      handleResetFilter={handleResetFilter}
                      handleClose={handleCloseFilterModal}
                      instructors={instructors}
                      checkedInstructors={selectedInstructors}
                    />
                  )}
                </span>
              </div>
              <div className="c-filter_btn-one c-mobile_filter_btn2">
                <span>Sort by</span>
                <select className="filterby" onChange={handleFilterChange}>
                  <option value={"latest"}>Newest</option>
                  <option value={"oldest"}>Oldest</option>
                  <option value={"title_asc"}>Title (A - Z)</option>
                  <option value={"title_desc"}>Title (Z - A)</option>
                  <option value={"long_duration"}>Longest</option>
                  <option value={"short_duration"}>Shortest</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* //////////////////////////////////loptop */}

      <div>
        <div className="tab-content fade-in" style={{ minHeight: "100vh" }}>
          <div id="menu2" className="tab-pane fade active show">
            {responseData?.map((category, index) => (
              <div key={index} id={category?.name?.split(" ")[0]}>
                <div key={category?._id} className="c-tab_cata-text">
                  <div id="c-mobile_tab_hide" className=" c-tab_cata mb-n9">
                    <div className="row m-0 c-tab_box">
                      <div className="col p-0">
                        <div className="c-filter_btn c-filter_btn-lessons">
                          <div
                            className="c-filter_btn-one c-filter_btn-tags unique "
                            style={{ cursor: "pointer", padding: "0px" }}
                          >
                            {/* <div className="c-filter_btn-tags"> */}
                            <span
                              onClick={handleOpenFilterModal}
                              className="c-filters-btn"
                              style={{
                                padding: "10px 16px",
                                display: "inline-block",
                              }}
                            >
                              {" "}
                              <img src={Filter} alt="" />
                              Filters
                            </span>
                            {/* Render FilterModal */}
                            {isFilterModalOpen && (
                              <FilterModal
                                isOpen={isFilterModalOpen}
                                tags={tags}
                                checkedTags={selectedTags}
                                checkedDuration={selectedDurations}
                                checkedLevels={selectedLevels}
                                handleTagChange={handleTagChange}
                                handleApplyFilter={handleApplyFilter}
                                handleResetFilter={handleResetFilter}
                                handleClose={handleCloseFilterModal}
                                instructors={instructors}
                                checkedInstructors={selectedInstructors}
                              />
                            )}
                          </div>
                          <div className="c-filter_btn-one">
                            <span>Sort by</span>
                            <select
                              className="filterby"
                              onChange={handleFilterChange}
                            >
                              <option value={"latest"}>Newest</option>
                              <option value={"oldest"}>Oldest</option>
                              <option value={"title_asc"}>Title (A - Z)</option>
                              <option value={"title_desc"}>
                                Title (Z - A)
                              </option>
                              <option value={"long_duration"}>Longest</option>
                              <option value={"short_duration"}>Shortest</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h4>{category?.name?.split(" ")[0]}</h4>
                </div>
                <div className="c-video ">
                  <div className="row c-video_textrow c-video_list pb-0">
                    <div
                      key={category?._id}
                      className="col-md-8 c-video_textcol c-mobile_videotext-1"
                    >
                      <h4>{category?.name}</h4>
                    </div>
                    <div className="col-md-4 c-video_textcol-a text-end c-mobile_videotext-2">
                      <a onClick={() => handleShuffle(category?.id)}>
                        <span style={{ color: "#3cb4e4" }}>
                          <i class="fa-solid fa-shuffle"></i>
                        </span>
                        <span style={{ color: "#3cb4e4" }}>Shuffle</span>
                      </a>
                    </div>
                  </div>
                  {/* Your video list here */}
                  <div className="c-video_list">
                    <div className="row m-0">
                      {category?.videos?.map((video, index) => (
                        <div key={index} className="col-md-3 c-mobile_col">
                          <div className="c-video_box">
                            <div className="c-video_icon-box">
                              <div>
                                <Card
                                  thumb_url={
                                    video?.videoDetails?.thumnailLink ||
                                    video?.thumnailLink
                                  }
                                  video_url={
                                    video?.videoDetails?.videolink ||
                                    video?.videoDetails
                                  }
                                  thumbnail_pic={
                                    video?.videoDetails?.thumnailLink ||
                                    video?.thumnailLink
                                  }
                                  video_duration={
                                    video?.videoDetails?.duration ||
                                    video?.duration
                                  }
                                  // playerRef={playerRef}
                                  onClick={() =>
                                    handlePlayerVideo(
                                      video?.videoDetails?._id || video?._id
                                    )
                                  }
                                />
                              </div>

                              {isLoggedIn && (
                                <PlaylistAddRoundedIcon
                                  fontSize="large"
                                  style={{
                                    cursor: "pointer",
                                    boxSizing: "border-box",
                                    position: "absolute",
                                    top: "1px",
                                    right: "1px",
                                  }}
                                  onClick={() =>
                                    handelSubmitPlaylist(
                                      video.videoDetails?._id || video?._id
                                    )
                                  }
                                />
                              )}

                              {isLoggedIn && (
                                <div className="c-video_time">
                                  <span
                                    id={video?.videoDetails?._id || video?._id}
                                    onClick={(e) => {
                                      const id = e?.currentTarget?.id; // Accessing the id from the clicked element
                                      handleSubmit(id, video);
                                    }}
                                  >
                                    {video?.videoDetails?.savedvideo ||
                                      video?.savedvideo || (savedVideoIds?.length > 0 && savedVideoIds?.includes(video?.videoDetails?._id || video?._id)) ? (
                                      <FcLike />
                                    ) : (
                                      <IoMdHeartEmpty />
                                    )}
                                  </span>
                                </div>
                              )}
                            </div>
                            {/* Add conditional rendering for Video_title */}
                            <div>
                              {video?.videoDetails ? (
                                <Video_title
                                  title={video.videoDetails.title || ""}
                                  tags={
                                    (video.videoDetails.tagsDetails || [])
                                      .map((tag) => ({
                                        _id: tag._id,
                                        name: tag.name,
                                        color: tag.color,
                                        priority: tag.priority || 0, // Assign priority or default to 0
                                      }))
                                      .sort((a, b) => a.priority - b.priority) // Sort tags based on priority
                                  }
                                  releaseDate={
                                    video.videoDetails.releaseDateTime || ""
                                  }
                                  onClick={() =>
                                    handlePlayerVideo(
                                      video?.videoDetails?._id || video?._id
                                    )
                                  }
                                  selectedTags={selectedTags}
                                  selectTagCallback={(tag) => {
                                    setSelectedTags((tags) => {
                                      if (tags.includes(tag._id)) {
                                        return tags.filter((t) => t !== tag._id);
                                      }
                                      return [...tags, tag._id];
                                    });
                                  }}
                                />
                              ) : (
                                <Video_title
                                  title={video?.title || ""}
                                  tags={
                                    (video?.tagsDetails || [])
                                      .map((tag) => ({
                                        _id: tag._id,
                                        name: tag.name,
                                        color: tag.color,
                                        priority: tag.priority || 0, // Assign priority or default to 0
                                      }))
                                      .sort((a, b) => a.priority - b.priority) // Sort tags based on priority
                                  }
                                  releaseDate={video?.releaseDateTime || ""}
                                  onClick={() =>
                                    handlePlayerVideo(
                                      video?.videoDetails?._id || video?._id
                                    )
                                  }
                                  selectedTags={selectedTags}
                                  selectTagCallback={(tag) => {
                                    setSelectedTags((tags) => {
                                      if (tags.includes(tag._id)) {
                                        return tags.filter((t) => t !== tag._id);
                                      }
                                      return [...tags, tag._id];
                                    });
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* Material-UI Pagination */}

                  <CustomPagination
                    totalPages={totvideos}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <AddToPlayListModal
        modal={modal}
        setModal={setModal}
        activeObject={videoData2}
        viewData={videos}
      />
    </>
  );
};

export default Exercise;
