import {
  Button,
  Card,
  CardContent,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Pagination,
} from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { ModerationContext } from "../../Layouts/AdminLayout/AdminDashboard";
import UserImage from "../UserImage/UserImage";
import Spinner from "../Spinner";
import axios from "axios";
import toast from "react-hot-toast";
import { styled } from "@mui/system";
import { Modal, Box } from "@mui/material";
import LazyImage from "../LazyImage/LazyImage";
import axiosConfig from "../../data/utils/axiosConfigInstance";
import { CommentsDisabledTwoTone } from "@mui/icons-material";

const ImageWrapper = styled("div")({
  width: "100%",
  height: "100%",
  cursor: "pointer",
});

const imageModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  border: "none !important",
  boxShadow: 24,
  outline: "none",
  borderRadius: "4px",
};

const InnerImageWrapper = styled("div")({
  position: "relative",
  width: "100%",
  height: "100%",
  border: "2px solid #383838",
  borderRadius: "4px",
});

const formatDateString = (dateString) => {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  return new Date(dateString).toLocaleDateString("en-GB", options);
};

const formatTimeString = (dateString) => {
  const options = { hour: "2-digit", minute: "2-digit", second: "2-digit" };
  return new Date(dateString).toLocaleTimeString("en-GB", options);
};

function ModerationView({ isImages }) {
  const {
    dialogMode,
    setDialogMode,
    setDialogIsOpen,
    comments,
    setComments,
    totalComments,
    setTotalComments,
    commentsCurrentPage,
    setCommentsCurrentPage,
    images,
    setImages,
    totalImages,
    setTotalImages,
    imagesCurrentPage,
    setImagesCurrentPage,
    isLoading,
    fetchContent
  } = useContext(ModerationContext);
  const [selectedImageView, setSelectedImageView] = useState("");
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [item, setItem] = useState(null);
  const [totalPages, setTotalPages] = useState(1);

  const [commentFilterValue, setCommentFilterValue] = useState("all");
  const [commentTableName, setCommentTableName] = useState('All Comments')
  const handleCommentFilterChange = (e) => {
    setCommentFilterValue(e.target.value)
  }

  useEffect(() => {
    switch(commentFilterValue) {
      case 'all':
        setCommentTableName('All Comments')
        break
      case 'pending':
        setCommentTableName('Pending Comments')
        break
      case 'rejected':
        setCommentTableName('Rejected Comments')
    }
  }, [commentFilterValue])

  const [imageFilterValue, setImageFilterValue] = useState("all");
  const [imageTablename, setImageTableName] = useState('All Images')
  const handleImageFilterChange = (e) => {
    setImageFilterValue(e.target.value)
  }

  useEffect(() => {
    switch(imageFilterValue) {
      case 'all':
        setImageTableName('All Images')
        break
      case 'pending':
        setImageTableName('Pending Images')
        break
      case 'rejected':
        setImageTableName('Rejected Images')
    }
  }, [imageFilterValue])

  useEffect(() => {
    if (isImages) {
      setTotalPages(Math.ceil(totalImages / 10));
    } else {
      setTotalPages(Math.ceil(totalComments / 10));
    }
  }, [isImages, totalImages, totalComments]);

  const actionOnItem = ({ item, approveType, approve }) => {
    const loadingToast = toast.loading(
      `${approve ? "Approving..." : "Declining..."}`,
      {
        position: "top-center",
      }
    );
  
    axiosConfig
      .post(`${process.env.REACT_APP_API_URL}/admin/content-validation`, {
        id: item._id,
        approve,
        approveType,
      })
      .then((res) => {
        toast.dismiss(loadingToast);
  
        if (approveType === "comment") {
          // Remove the approved/rejected comment from state
          setComments((prevComments) =>
            prevComments.filter((comment) => comment._id !== item._id)
          );
          if (approve) setTotalComments((prev) => prev - 1);
        } else {
          // Remove the approved/rejected image from state
          setImages((prevImages) =>
            prevImages.filter((image) => image._id !== item._id)
          );
          if (approve) setTotalImages((prev) => prev - 1);
        }
  
        setItem(null);
  
        toast.success(
          `Successfully ${approve ? "approved" : "declined"} ${approveType}!`,
          {
            position: "top-center",
          }
        );
  
        if (approveType === "comment" && approve) {
          setDialogMode({
            isApproved: true,
            videoID: item.videoID,
            commentId: item._id,
          });
          setDialogIsOpen(true);
        }
      })
      .catch((err) => {
        toast.error("An error occurred. Please try again.");
      });
  };
  

  const handleApproveComment = (comment) => {
    setItem(comment);
    setDialogMode("approve_comment");
    setDialogIsOpen(true);
  };
  const handleDeclineComment = (comment) => {
    setItem(comment);
    setDialogMode("decline_comment");
    setDialogIsOpen(true);
  };
  const handleApproveImage = (image) => {
    setItem(image);
    setDialogMode("approve_image");
    setDialogIsOpen(true);
  };
  const handleDeclineImage = (image) => {
    setItem(image);
    setDialogMode("decline_image");
    setDialogIsOpen(true);
  };

  const paginationCallback = useCallback(
    (event, page) => {
      if (isImages) {
        setImagesCurrentPage(page);
      } else {
        setCommentsCurrentPage(page);
      }
    },
    [isImages]
  );

  const handleActionOnItem = useCallback(() => {
    if (typeof dialogMode === "object" && item) {
      actionOnItem({
        item,
        approveType: dialogMode.approveType,
        approve: dialogMode.approve,
      });
    } else if (dialogMode === null) {
      setItem(null);
    }
  }, [dialogMode, item]);

  useEffect(() => {
    handleActionOnItem();
  }, [dialogMode]);

  if (isLoading) {
    return (
      <div className={"c-admin-moderation-loading-wrapper"}>
        <Spinner />
      </div>
    );
  }

  if (isImages && images.length === 0) {
    return <div>No images are pending validation!</div>;
  } else if (!isImages && comments.length === 0) {
    return <div>No comments are pending validation!</div>;
  }

  const TableNameAndFilter = ({type}) => {
    return (
      <div className="" style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <div className="" style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '20px'
        }}>
          {type === "image" ? (
            <>{imageTablename}</>
          ) : (
            <>{commentTableName}</>
          )}
        </div>
        <div class="">
          <div class="sort-container">
            <label for="sort">Filter by Status:</label>
            <select
              id="sort"
              class="sort-dropdown"
              value={`${(type === "image" ? imageFilterValue : commentFilterValue)}`}
              onInput={(event) => {
                if (type === "image") {
                  setImageFilterValue(event.target.value);
                } else {
                  setCommentFilterValue(event.target.value);
                }
              }}
            >
              <option value="all">All</option>
              <option value="pending">Pending</option>
              <option value="rejected">Rejected</option>
            </select>
          </div>
        </div>
      </div>
    )
  }

  const getCommentStatus = (comment) => {
    if (comment.isDeleted) {
      return "Rejected"
    } else {
      if (comment.requiresValidation) {
        return "Pending"
      } else {
        return "Approved"
      }
    }
  }

  const getImageStatus = (image) => {
    if (image.isDeleted) {
      return "Rejected"
    } else {
      if (image.requiresValidation) {
        return "Pending"
      } else {
        return "Approved"
      }
    }
  }

  return (
    <div className={"c-admin-moderation-container"}>
      
      <Card className="mb-3">
        <CardContent>
          {isImages ? (
            <>
              <TableNameAndFilter type='image'/>
            </>
          ) : (
            <><TableNameAndFilter type='comment'/></>
          )}
          <Table>
            <TableHead>
              {isImages ? (
                  <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Full Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                ) : (               
                  <TableRow>
                    <TableCell>Content</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Video</TableCell>
                    <TableCell>Full Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Created At</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
              )}
            </TableHead>
            <TableBody>
              {isImages
                ? images.map((image) => 
                  {
                    if (imageFilterValue !== 'all' && getImageStatus(image).toLocaleLowerCase() !== imageFilterValue) {
                      return null
                    } else {
                      return (
                        <TableRow key={image._id}>
                          <TableCell>
                            <ImageWrapper
                              onClick={() => {
                                setSelectedImageView(image.url);
                                setImageModalOpen(true);
                              }}
                            >
                              <UserImage imageURL={ image.url } withBorder />
                            </ImageWrapper>
                            <Modal
                              open={imageModalOpen}
                              onClose={() => {
                                setImageModalOpen(false);
                              }}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                            >
                              <Box sx={imageModalStyle}>
                                <InnerImageWrapper>
                                  <LazyImage
                                    src={selectedImageView}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      // border: "2px solid #383838",
                                      borderRadius: "4px",
                                    }}
                                    alt={"Selected image view fullscreen"}
                                  />
                                </InnerImageWrapper>
                              </Box>
                            </Modal>
                          </TableCell>
                          <TableCell>
                            <div>
                              { 
                                getImageStatus(image)
                              }
                            </div>
                          </TableCell>
                          <TableCell>
                            {image.userDetails[0]?.firstname}{" "}
                            {image.userDetails[0]?.lastname}
                          </TableCell>
                          <TableCell>{image.userDetails[0]?.email}</TableCell>
                          <TableCell>
                            <div className={"c-admin-moderation-date-wrapper"}>
                              <div className={"c-admin-moderation-date-entry"}>
                                {formatDateString(image.createdAt)}
                              </div>
                              <div className={"c-admin-moderation-date-entry"}>
                                {formatTimeString(image.createdAt)}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="outlined"
                              color="success"
                              onClick={() => handleApproveImage(image)}
                            >
                              Approve
                            </Button>
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => handleDeclineImage(image)}
                            >
                              Decline
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                    }
                  }
                )
                : comments.map((comment) => 
                  {
                    if (commentFilterValue !== 'all' && getCommentStatus(comment).toLocaleLowerCase() !== commentFilterValue) {
                      return null
                    } else {
                      return (
                        <TableRow key={comment._id}>
                          <TableCell>
                            <div className={"c-admin-moderation-comment-content"}>
                              {comment.content.split("\n").map((line, index) => (
                                <p key={index}>{line}</p>
                              ))}
                            </div>
                          </TableCell>
                          <TableCell>
                              <div>
                                {getCommentStatus(comment)}
                              </div>
                          </TableCell>
                          <TableCell>
                            <a
                              href={`/PlayerPage?v=${comment.videoID}`}
                              target={"_blank"}
                            >
                              <img
                                src={comment.videoThumbnail}
                                width={120}
                                height={120}
                                style={{ objectFit: "cover", borderRadius: "8px" }}
                                alt={"Video thumbnail image"}
                              />
                            </a>
                          </TableCell>
                          <TableCell>
                            {comment.userDetails[0].firstname}{" "}
                            {comment.userDetails[0].lastname}
                          </TableCell>
                          <TableCell>{comment.userDetails[0].email}</TableCell>
                          <TableCell>
                            <div className={"c-admin-moderation-date-wrapper"}>
                              <div className={"c-admin-moderation-date-entry"}>
                                {formatDateString(comment.createdAt)}
                              </div>
                              <div className={"c-admin-moderation-date-entry"}>
                                {formatTimeString(comment.createdAt)}
                              </div>
                            </div>
                          </TableCell>
                          {/* Add more cells based on your data */}
                          <TableCell>
                            <Button
                              variant="outlined"
                              color="success"
                              onClick={() => handleApproveComment(comment)}
                            >
                              Approve
                            </Button>
                            <Button
                              variant="outlined"
                              color="error"
                              onClick={() => handleDeclineComment(comment)}
                            >
                              Decline
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                    }
                  }
                )}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      <div
        style={{
          margin: "0 auto",
          width: "max-content",
          paddingBottom: "24px"
        }}
      >
        <Pagination
          count={totalPages}
          page={isImages ? imagesCurrentPage : commentsCurrentPage}
          onChange={paginationCallback}
          variant="outlined"
          shape="rounded"
        />
      </div>
    </div>
  );
}

export default ModerationView;
