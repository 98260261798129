import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import { FormFeedback } from "reactstrap";
import toast from "react-hot-toast";
import {
  useAddToPlayListMutation,
  useDropDownPersonalListQuery,
  useSubmitPersonalPlayListMutation,
} from "../../../service";
import "./add_to_playlist.css";
import { useSelector } from "react-redux";
import { userInfoAtom } from "../../../data/atoms/utilsAtoms";
import { useAtomValue } from "jotai";


function AddToPlayListModal({ modal, setModal, activeObject, viewData }) {
  const userData = useAtomValue(userInfoAtom)
  const userInfo = useSelector((state) => state.authState.userInfo);
  const personlaPlayList = useDropDownPersonalListQuery(userData.user?.userId);
  const [reqPersonal, resPersonal] = useSubmitPersonalPlayListMutation();
  const [reqAddToPlayList, resAddToPlayList] = useAddToPlayListMutation();

  const [inputVisibled, setInputVisibled] = useState(false);
  const [playListName, setPlayListName] = useState("");
  const [error, setError] = useState("");

  const [allPersonalList, setAllPersonalList] = useState([]);
  const [selectedPlayList, setSelectedPlayList] = useState([]);


  useEffect(() => {
    if (
      personlaPlayList?.isSuccess &&
      personlaPlayList?.data &&
      Array.isArray(personlaPlayList?.data) &&
      personlaPlayList?.data?.length > 0
    ) {
      const res1 = personlaPlayList?.data?.filter(
        (el) => el?.value !== viewData?._id
      );
      setAllPersonalList(res1);
    }
  }, [personlaPlayList?.isSuccess, viewData]);

  const handleCheckPlayList = (e, fld) => {
    if (e.target.checked) {
      setSelectedPlayList([
        { name: fld?.label, object_id: fld?.value, video_object: activeObject },
      ]);
    } else {
      const selectedPlayListRes = selectedPlayList?.filter(
        (el) => el?.object_id !== fld?.value
      );
      setSelectedPlayList(selectedPlayListRes);
    }
  };

  const handleChange = (value) => {
    setPlayListName(value);
    setError("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      selectedPlayList &&
      Array.isArray(selectedPlayList) &&
      selectedPlayList?.length > 0
    ) {
      reqAddToPlayList({ myArray: selectedPlayList });
    } else if (inputVisibled && playListName) {
      reqPersonal({
        name: playListName,
        description: "",
        videos: activeObject ? [activeObject] : [],
        userId: userInfo?.userId,
      });
    } else if (inputVisibled && !playListName) {
      setError("Name is required");
    }
  };

  useEffect(() => {
    if (resPersonal?.isSuccess) {
      toast.success("Added to playlist successfully", {
        position: "top-center",
        duration: 3000,
      });
      // setTimeout(() => {
      //   navigate('/personal-playlist')
      // }, 1000);
      setModal(false);
    }
    if (resPersonal?.isError) {
      toast.error("Something went wrong", {
        position: "top-center",
        duration: 3000,
      });
    }
  }, [resPersonal]);

  useEffect(() => {
    if (resAddToPlayList?.isSuccess) {
      toast.success("Add to playlist successfully", {
        position: "top-center",
        duration: 3000,
      });
      // setTimeout(() => {
      //   navigate('/personal-playlist')
      // }, 1000);
      setModal(false);
    }
    if (resAddToPlayList?.isError) {
      toast.error("Something went wrong", {
        position: "top-center",
        duration: 3000,
      });
    }
  }, [resAddToPlayList]);

  const cancelFuc = () => {
    // reset();
    setInputVisibled(false);
    setPlayListName("");
    setError("");
    setSelectedPlayList([]);
    setModal(false);
  };

  return (
    <Modal centered isOpen={modal} toggle={cancelFuc}>
      <form>
        <div className="play__listing">
          <div className="play_list_heading">
            <div className="play_heading">Add to Playlist</div>
            <div className="close_icon" onClick={cancelFuc}></div>
          </div>
          <div className="list_of_play">
            <div className="play_l">
              {allPersonalList &&
                Array.isArray(allPersonalList) &&
                allPersonalList?.length > 0 &&
                allPersonalList?.map((el, i) => {
                  return (
                    <label
                      className="control_play control--checkbox"
                      htmlFor={el?.value}
                      key={i}
                    >
                      {el?.label}
                      <input
                        type="checkbox"
                        name={el?.label}
                        id={el?.value}
                        value={el?.value}
                        onChange={(e) => handleCheckPlayList(e, el)}
                      />
                      <div className="control__indicator"></div>
                    </label>
                  );
                })}
            </div>
          </div>

          <div
            className="creat_n_play"
            style={{ cursor: "pointer" }}
            onClick={() => setInputVisibled(!inputVisibled)}
          >
            <div className="play_heading">Create New Playlist</div>
            <div className="close_icon"></div>
          </div>
          <div
            className={`add_name_play ${inputVisibled ? "d-block" : "d-none"}`}
          >
            <input
              type="text"
              placeholder="Enter name of new playlist"
              onChange={(e) => handleChange(e.target.value)}
            />
            {error && <FormFeedback>{error}</FormFeedback>}
          </div>
          <button className="save_play_list" onClick={(e) => handleSubmit(e)}>
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
}

export default AddToPlayListModal;
