import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useMediaQuery } from '@mui/material';

const ChangeUserModal = ({ open, handleClose, handleCancelClick, userType, onSave }) => {
  const isMobile = useMediaQuery('(max-width:600px)'); // Media query for mobile screens

  const handleConfirmClick = () => {
    onSave();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          p: isMobile ? 3 : 6,
          backgroundColor: "#27272a",
          margin: "auto",
          mt: isMobile ? "10vh" : "20vh",
          width: isMobile ? '90%' : 600,
          borderRadius: "10px",
          position: "relative",
          color: "white",
        }}
      >
        <div className="change-user-text">
          {userType === "individual-user" ? (
            <p>You are currently registered as a user on our platform. If you switch your account type, you will lose your previous account subscription and data. Are you sure you want to convert to a Studio Account?</p>
          ) : (
            <p>You are currently registered as {userType}. If you switch your account type, you will lose your previous account subscriptions and data.</p>
          )}
        </div>
        <div className="change-user-button-section">
          <button className="c-form_my-btn" onClick={handleConfirmClick}>
            Confirm
          </button>
          <button className="no-seats-left-button" onClick={handleCancelClick}>
            Cancel
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default ChangeUserModal;
