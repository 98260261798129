import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import api from "../data/api";

function AddCategory() {
  axios.defaults.withCredentials = true;
  const redirect =useNavigate();
  const [category, setCategory] = useState("");
  const [response, setResponse] = useState("");
  const [deleteResponse, setDeleteReponse] = useState("");
  const [categoriesList, setCategoriesList] = useState([]);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  useEffect(() => {
    api.getCategory().then((response) => {
      setCategoriesList(response.data);
    });
  }, [response]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(category);
    const firstLetter = category[0];
    const asciiValue = firstLetter.charCodeAt(0);

    if (
      (asciiValue >= 65 && asciiValue <= 90) ||
      (asciiValue >= 97 && asciiValue <= 122)
    ) {
      console.log("The first letter is a letter.");
      const capitalizedCategory =
        category.charAt(0).toUpperCase() + category.slice(1);
      console.log(`capitalizedCategory: ${capitalizedCategory}`);

      try {
        const response = await api.addCategory(capitalizedCategory);

        if (response.status === 209) {
          setCategory("");
          setResponse(response.data.message);
          // Refresh the categories list after adding a new category
          api.getCategory().then((response) => {
            setCategoriesList(response.data);
          });
        } else if (response.status === 201) {
          setCategory("");
          setResponse(response.data.message);
          toast.error('Please Login Again', {
            position: "top-center",
            duration: 3000,
          });
          redirect('/login');
        }
      } catch (error) {
        console.error("Adding category failed:", error);
        setResponse("Failed to add category");
      }
    } else {
      setResponse("The first character is not a letter.");
      console.log(response);
    }

    setTimeout(() => {
      setResponse("");
    }, 3000);
  };

  const handleRemoveCategory = async () => {
    try {
      const response = await api.removeCategory(selectedCategoryId);

      if (response.status === 200) {
        setDeleteReponse(response.data.message);
        api.getCategory().then((response) => {
          setCategoriesList(response.data);
        });
      }else if(response.status === 201)
      {
        toast.error('Please Login Again', {
          position: "top-center",
          duration: 3000,
        });
        setDeleteReponse(response.data.message);
        redirect('/login');
      }
       else {
        setDeleteReponse(response.data.message);
      }
    } catch (error) {
      console.error("Removing category failed:", error);
      setDeleteReponse("Failed to remove category");
    }

    setTimeout(() => {
      setDeleteReponse("");
    }, 3000);

    setConfirmationDialogOpen(false);
    setSelectedCategoryId(null);
  };

  const handleConfirmationDialogOpen = (categoryId) => {
    setConfirmationDialogOpen(true);
    setSelectedCategoryId(categoryId);
  };

  const handleConfirmationDialogClose = () => {
    setConfirmationDialogOpen(false);
    setSelectedCategoryId(null);
  };

  return (
    <div className="App white">
      <Typography variant="h4" component="div" gutterBottom>
        Category List
      </Typography>
      <form>
        <div>
          <label>Enter Category:</label>
          <input
            className="form-control"
            type="text"
            name="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />
        </div>
        <Button
          onClick={handleSubmit}
          className="mt-2"
          variant="primary"
          type="button"
        >
          ADD
        </Button>
      </form>
      {response && <div className="alert-success">{response}</div>}
      <List style={{ width: "50%" }}>
        {categoriesList.map((category) => (
          <Card key={category._id} style={{ margin: "8px" }}>
            <CardContent>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <ListItem>
                  <ListItemText primary={category.name} />
                </ListItem>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleConfirmationDialogOpen(category._id)}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </CardContent>
          </Card>
        ))}
      </List>

      {deleteResponse && <div className="alert-danger">{deleteResponse}</div>}
      <Dialog
        open={confirmationDialogOpen}
        onClose={handleConfirmationDialogClose}
      >
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this category?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmationDialogClose}>No</Button>
          <Button onClick={handleRemoveCategory}>Yes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddCategory;
